import { Col, Row } from "react-bootstrap";

function Token() {
    return (
        <section className="token_section__token">
            <div className="inner__token">
                <Row style={{ height: '100%' }}>
                    <Col className="col_token_info">
                        <div className="val_token_info">150,000,000</div>
                        <div className="desc_token_info">$SPARK Token Supply</div>
                    </Col>
                    {/*
                    <Col className="col_token_info">
                        <div className="val_token_info">$0.053</div>
                        <div className="desc_token_info">Public Sale Price</div>
                    </Col>
                    <Col className="col_token_info">
                        <div className="val_token_info">14 Dec, 2024</div>
                        <div className="desc_token_info">Public Sale Date</div>
                    </Col>
                    */}

                </Row>
            </div>
        </section>
    )
}

export default Token;