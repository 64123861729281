import logoMtech from '../../assets/img/m-tech-logo.png';
import logoIskra from '../../assets/img/iskra-text.png';
const Sponsors = () => {
  const goTo = (link) => {
    window.open(link, "_blank");
}

  return (
    <>
      <h2 className="sponsor-h2">INVESTORS</h2>
      <div className="sponsor">
        {/*  <img className='sponsor-img' src={logoMtech} alt='mtech' />*/}
        <img  onClick={() => goTo('https://iskra.world/')} className='sponsor-img-iskra' src={logoIskra} alt='iskra' />
      </div>
    </>

  );
};

export default Sponsors;