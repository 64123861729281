import {useState, useEffect} from 'react';
import { collection, query, orderBy, getDocs} from "firebase/firestore";
import db from '../../redux/firebase';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FireBaseImages from './FireBaseImages';
import { doc, deleteDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { useDispatch} from 'react-redux';
import { logOutFirebaseCred } from '../../redux/reducers/adminFirebase';

export default function NewsAdmin(){
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchItems = async () => {
          try {
            // Extract data from all documents
            const q = query(collection(db.news, 'news'), orderBy('created_at', 'desc'));
            const querySnapshot = await getDocs(q);
            const itemsData = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              created_at: convertDate(doc.data().created_at)
            }));
            setItems(itemsData);
          } catch (error) {
            console.error('Error fetching documents: ', error);
          }
        };
    
        fetchItems();
      }, []);

    const convertDate = (timestamp) => {
        try{
            // Convert Firestore Timestamp to JavaScript Date object
            const jsDate = timestamp.toDate();

            // Format the date as "Full month name Day, YEAR"
            const options = { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
            };
            const formattedDate = new Intl.DateTimeFormat('en-US', options).format(jsDate);
            return formattedDate;
        }catch{
            return timestamp?.seconds;
        }
    }

    
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'title', headerName: 'Title', width: 250 },
        { field: 'author', headerName: 'Author', width: 150 },
        { field: 'created_at', headerName: 'Published Date', width: 150 },
        { field: 'isTop', headerName: 'Top News', width: 150 },
        { field: 'isVisible', headerName: 'Visible', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
              <div>
                <Button variant="contained">
                  <span style={{ cursor: 'pointer' }} onClick={() => navigate('/admin/news/'+params.row.id)}>
                    Edit
                  </span>
                </Button>

                <Button color="error" className="ms-2" variant="contained">
                  <span style={{ cursor: 'pointer' }} onClick={() => handleDeleteDialogOpen(params.row)}>
                    Delete
                  </span>
                </Button>
              </div>
            ),
          },
    ];

    const handleDeleteDialogOpen = (news) => {
        setSelectedNews(news);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
      setSelectedNews(null);
      setDeleteDialogOpen(false);
  };

  const logOutFirebase = async () => {
    try {
      await signOut(db.auth);
      dispatch(logOutFirebaseCred());
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleDeleteNews = async () => {

    if (selectedNews) {
      try {
        const docRef = doc(db.news, "news", selectedNews.id);
        await deleteDoc(docRef);
        console.log(`Document with ID ${selectedNews.id} successfully deleted from collection news.`);

        const updatedItems = items.filter(item => item.id !== selectedNews.id);
        setItems(updatedItems);
      } catch(err){
        console.log(err)
      }
      finally {
        handleDeleteDialogClose(); // Close the delete dialog
      }
    }
  };

    return(
        <div className="questing_page" style={{paddingTop: '5rem'}}>
            <Container>
            -------------------------------
            <div className="pb-3">All News</div>
                <Button variant="outlined" className="mb-4 me-2" onClick={() => logOutFirebase()}>Log Out</Button>
                <Button variant="contained" className="mb-4 me-2" onClick={() => navigate('/admin/news/new')}>Add New</Button>
                <div style={{maxWidth: '95vw', overflowX: 'scroll'}}>
                  <DataGrid
                      rows={items}
                      columns={columns}
                      pageSize={15}
                      autoHeight
                  />
                </div>

            </Container>
            
            <div className="pt-5 mt-5 pb-5">
              <FireBaseImages/>
            </div>

             {/* Delete confirmation dialog */}
             <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>Delete Image</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete "{selectedNews?.title}"?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDeleteDialogClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDeleteNews} color="secondary">
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}