import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';

import { logOutIskra, setIskraCred, setUserInfoIskra } from './redux/reducers/userIskraReducer';
import CheckIcon from '@mui/icons-material/Check';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ScrollTop from './helpers/ScrollTop.js';
import NavBar from "./layouts/NavBar.js";
import HomePage from './views/HomePage.js';
import { CssBaseline, Fab, ThemeProvider, createTheme } from "@mui/material";
import { KeyboardArrowUpOutlined } from "@mui/icons-material";
import Footer from "./layouts/Footer.js";
import Tokenomics from "./views/TokenomicsPage.js";
import Forge from './components/Forge.jsx';
import { createBrowserHistory } from "history";
import AboutPage from "./views/AboutPage.js";
import { useEffect, useCallback, useState } from "react";
import QuestingPage from './views/QuestingPage.js';
import { IskraSDK } from '@iskraworld/iskra-sdk'
import Guardians from './views/Guardians.js';
import UserSettingsPage from './views/UserSettingsPage.js';
import SetAccount from './components/SetAccount';
import RewardsQuesting from './views/RewardsQuesting';
import LogInAdmin from './views/admin/LogInAdmin';
import Dashboard from './views/admin/Dashboard';
import News from './views/News';
import NewsDescription from './views/NewsDescription';
import LogInFireBase from './views/admin/LogInFirebase';
import NewsAdmin from './views/admin/NewsAdmin';
import NewsAdminEdit from './views/admin/NewsAdminEdit';

createBrowserHistory({ basename: process.env.PUBLIC_URL });

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#234093',
      light: '#4dc6dc',
      dark: '#0e1524',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff3b5',
      light: '#f3e5f5',
      dark: '#ab47bc',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      main: '#ffa726',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#fff',
    },
    info: {
      main: '#29b6f6',
      light: '#90caf9',
      dark: '#0288d1',
      contrastText: '#fff',
    },
    success: {
        main: '#66bb6a',
        light: '#81c784',
        dark: '#388e3c',
        contrastText: '#fff',
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: '#234093'
          },
        },
      },
    },
  },
});


function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const appId = process.env.REACT_APP_ISKRA_ID; // App ID issued by Iskra
const phase = process.env.REACT_APP_ISKRA_PHASE; // dev (test environment) or prod (production environment)

function App() {
  const dispatch = useDispatch();
  const history = createBrowserHistory()
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const {account, userId} = useSelector(state => state.userIskra);
  const {user} = useSelector(state => state.userQuesting);
  const {userF} = useSelector(state => state.userFirebase);

  const [showAcc, setShowAcc] = useState(false);

  useEffect(() => {
    if(user?.id !== -1 && user?.id !== 1){
      if(!user?.username){
        setShowAcc(true);
      }
    }
  }, [user])
  const { pathname } = useLocation();
  
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccess('');
  };

  const handleCloseError = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setError('');
};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const initializeIskra = useCallback(
    async () => {
        // callback
        await IskraSDK.instance().initializeAsync(phase, appId);

        if(IskraSDK?.instance()?.isInitialize()){
          await IskraSDK.instance().loadLastLogin((data, error) => {
            if (error != null) {
              dispatch(logOutIskra())
              return;
            }

            if(data?.userId !== userId){
              dispatch(setIskraCred({userId: data?.userId, accessToken: data?.accessToken}));
              dispatch(setUserInfoIskra(data));
            }
          });
        }
    },
    [dispatch, userId],
  )
  
  useEffect(() => {
      initializeIskra();
  }, [initializeIskra])

  const subpath = '';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="root">
        <NavBar subpath={subpath} setSuccess={setSuccess} setError={setError}/>
        <SetAccount setShowAcc={setShowAcc} showAcc={showAcc} setSuccess={setSuccess} />

        <Snackbar
          open={success !== ''}
          TransitionComponent={SlideTransition}
          autoHideDuration={3000}
          onClose={handleClose}
          message={
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          {success}
          </Alert>}
          />
          <Snackbar
          open={error !== ''}
          TransitionComponent={SlideTransition}
          autoHideDuration={3000}
          onClose={handleCloseError}
          message={
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="error">
          {error}
          </Alert>}
          />

          <Routes basename={subpath} history={history}>
              <Route
                path="/"
                exact
                element={
                  <>
                      <HomePage setSuccess={setSuccess} setError={setError}/>
                  </>
                }
              />
              <Route
                path="/tokenomics"
                element={
                  <>
                      <Tokenomics/>
                      <Footer/>
                  </>
                }
              />
               <Route
                path="/news"
                element={
                  <>
                      <News/>
                      <Footer/>
                  </>
                }
              />
              <Route
                path="/news/:id/:isMostRead?"
                element={
                  <>
                      <NewsDescription/>
                      <Footer/>
                  </>
                }
              />
              <Route
                path="/about"
                element={
                  <>
                      <AboutPage/>
                      <Footer/>
                  </>
                }
              />
              <Route
                path="/forge"
                element={
                  <>
                      <Forge/>
                      <Footer/>
                  </>
                }
              />
              <Route
                      path="/guardians"
                      element={
                        <>
                            <Guardians/>
                            <Footer/>
                        </>
                      }
                    />
              <Route
                path="/user/*"
                element={
                  <>
                  {!account && user.id === -1 &&
                    <Navigate to="/" /> 
                  }
                  {(account || user) &&
                  <Routes>
                      <Route
                      path="/"
                      element={
                        <>
                          <Navigate to="/user/settings" /> 
                        </>
                      }
                      />
                      <Route
                      path="/airdrop"
                      element={
                        <>
                            <QuestingPage/>
                            <Footer/>
                        </>
                      }
                      />
                      <Route
                      path="/airdrop/:reward_id/:name"
                      element={
                        <>
                            <RewardsQuesting/>
                            <Footer/>
                        </>
                      }
                      />
                      <Route
                      path="/settings/:path_settings?"
                      element={
                        <>
                            <UserSettingsPage setSuccess={setSuccess} setError={setError}/>
                            <Footer/>
                        </>
                      }
                      />
                  </Routes>
                  }
                  </>
                }
              />
              <Route path="/admin/*" element={
                <Routes>
                    <Route path='/*' element={
                      <>
                      {user.id === 1 && 
                        <Routes>
                          <Route path='/' element={<>
                            <Dashboard />
                            <Footer/>
                          </>} /> 
                          <Route path='/:path_settings?' element={<>
                            <Dashboard setSuccess={setSuccess} setError={setError}/>
                            <Footer/>
                          </>} /> 
                        </Routes>
                      }
                      {user.id !== 1 && <Navigate to='/admin/login'/>} 
                      </>
                    }/>
                    <Route path='/login' element={
                      <>
                      {user.id !== 1 && 
                        <>
                          <LogInAdmin setSuccess={setSuccess}/>
                          <Footer/>
                        </>
                      }
                      {user.id === 1 && <Navigate to='/admin'/>} 
                      </>
                      
                    }
                  />
                   <Route path='/news/*' element={
                      <>
                      {!userF && 
                        <>
                          <LogInFireBase setSuccess={setSuccess}/>
                          <Footer/>
                        </>
                      }
                      {userF?.uid === "ZhhRt5ExSfgMraUJlxK17CjCHR13" && 
                      <Routes>
                        <Route path='/' element={<>
                          <NewsAdmin/>
                          <Footer/>
                        </>} 
                        /> 
                        <Route path='/:id?' element={<>
                          <NewsAdminEdit setSuccess={setSuccess} setError={setError}/>
                          <Footer/>
                        </>} 
                        /> 
                    </Routes>
                    } 
                      </>
                      
                    }
                  />
                </Routes>
              } />
              <Route path="/*" element={<Navigate to='/'/>} />
          </Routes>
       

        <ScrollTop>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpOutlined />
          </Fab >
        </ScrollTop>
      </div>
    </ThemeProvider>
  );

}

export default App;
