import { Button, IconButton, TextField, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container} from '@mui/material';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import { list, getDownloadURL, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';
import db from '../../redux/firebase';
import {
    DataGrid,
    GridToolbar 
  } from '@mui/x-data-grid';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';

export default function FireBaseImages(){
    const [validated, setValidated] = useState(false);
    const [img, setImg] = useState(null);
    const [imgPreview, setImgPreview] = useState('');
    const [images, setImages] = useState([]);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const fetchImages = async () => {
        try {
          const imagesRef = ref(db.storage, 'news'); // Replace 'photos' with your storage folder

          const listResult = await list(imagesRef);

          // Fetch download URLs for each item
          const urlsPromises = listResult.items.map(async (item, index) => {
            const url = await getDownloadURL(item);
            return { id: index + 1, name: item.name, url }; // Assign a unique id to each item
          });
    
          // Resolve all promises to get final array of image objects with id, name, and URL
          const urls = await Promise.all(urlsPromises);
          setImages(urls);
        } catch (error) {
          console.error('Error fetching images:', error);
        }
    };

    useEffect(() => {
        fetchImages();
      }, []); 

    const submit = (e) => {
        e.preventDefault();
        setSuccess('');
        setError('');

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.stopPropagation();
        }

        setValidated(true);

        if (img) {
            const storageRef = ref(db.storage, `news/${img.name}`);
            const uploadTask = uploadBytesResumable(storageRef, img);
      
            uploadTask.on('state_changed', 
            (snapshot) => {
                // Handle progress
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
              (error) => {
                setError('Error uploading file:', error);
              },
              () => {
                // Handle successful upload
                setSuccess('File uploaded successfully');
                fetchImages(); // Fetch images again to update the table
                setImg(null); // Clear selected file
                setImgPreview('');
              }
            );
          }
    }

    const columns = [
        { field: 'name', headerName: 'Name', width: 200 },
        {
            field: 'url',
            headerName: 'Image URL',
            width: 300,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={params.value}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Tooltip title="Copy URL">
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(params.value);
                    }}
                    size="small"
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Image">
                  <IconButton
                    onClick={() => handleDeleteDialogOpen(params.row)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ),
          },
      ];

    const handleDeleteDialogOpen = (image) => {
        setSelectedImage(image);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setSelectedImage(null);
        setDeleteDialogOpen(false);
    };

    const handleDeleteImage = async () => {
        if (selectedImage) {
          try {
            await deleteObject(ref(db.storage, `news/${selectedImage.name}`));
            setSuccess('File deleted successfully');
            fetchImages(); // Fetch images again to update the table
          } catch (error) {
            setError('Error deleting file:', error);
          } finally {
            handleDeleteDialogClose(); // Close the delete dialog
          }
        }
      };
      
    return(
        <Container>
            -------------------------------
            <div className="pb-3">FireStore images</div>
            <Form className="mb-5" noValidate validated={validated} onSubmit={(e) => submit(e)} data-bs-theme="dark">
                <Form.Group controlId="formFile" className="mb-3">
                    <InputGroup className="mb-3">
                        <Form.Control required value={imgPreview} onChange={e => {setImg(e.target.files[0]); setImgPreview(e.target.value)}} type="file" />
                        {img &&
                            <Button onClick={() => {setImg(null); setImgPreview('');}} variant="outlined">X</Button>
                        }
                    </InputGroup>
                </Form.Group>

                <Button type="submit" variant="contained">Add New</Button>
                {error && <p className="text-error">{error}</p>}
                {success && <p className="text-success">{success}</p>}
            </Form>
            <div style={{maxWidth: '95vw', overflowX: 'scroll'}}>
              <DataGrid
                  rows={images}
                  columns={columns}
                  pageSize={5}
                  getRowId={(row) => row.url} 
                  rowsPerPageOptions={[5, 10, 20]}
                  disableSelectionOnClick
                  slots={{ toolbar: GridToolbar }}
                  autoHeight
              />
            </div>
            {/* Delete confirmation dialog */}
            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>Delete Image</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete "{selectedImage?.name}"?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDeleteDialogClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDeleteImage} color="secondary">
                    Delete
                </Button>
                </DialogActions>
            </Dialog>

        </Container>
    )
}