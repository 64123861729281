import * as React from 'react';
import {
  DataGrid,
  GridColDef,
  GridToolbar 
} from '@mui/x-data-grid';

import axiosInstance from '../../redux/api';

export default function TableUsers({meta, loading, users, setUsers, setSuccess, setError, setLoading, setMeta}){
   
    const [paginationModel, setPaginationModel] = React.useState({
      page: meta?.current_page ? meta.current_page - 1 : 0,
      pageSize: meta?.per_page ? meta.per_page  : 50
    });
    const rowCount =  meta?.total ? meta.total : users.length;

    const columns: GridColDef[] = [
        { field: 'user_id', headerName: 'ID', width: 110 },
        { field: 'wallet_address', headerName: 'Wallet Address', width: 120},
        { field: 'username', headerName: 'Username', width: 110},
        { field: 'email', headerName: 'Email', width: 130},
        { field: 'credits', headerName: 'Credits', width: 80, type: 'number'},
        { field: 'last_active', headerName: 'Last Active', width: 110, type: 'number'},
      ];

    const handlePaginationModelChange = async (newPaginationModel: GridPaginationModel) => {
      setLoading(true);
      try{
        const result = await axiosInstance.get('admin/users?page='+(newPaginationModel.page+1));
        if(result?.data?.data){
            setUsers(result.data.data);
          setMeta(result?.data?.meta);
        }
        
        setLoading(false);
        setPaginationModel(newPaginationModel);
      }catch{
        setLoading(false);
        setPaginationModel(newPaginationModel);
      }
    };

    return(
        <div style={{ height: 400, width: '100%' }}>
        <DataGrid
            rows={users}
            columns={columns}
            pageSizeOptions={[(meta?.per_page ? meta?.per_page : 50)]}
            paginationMode="server"
            paginationModel={paginationModel}
            rowCount={rowCount}
            loading={loading}
            onPaginationModelChange={handlePaginationModelChange}
            slots={{ toolbar: GridToolbar }}
        />
        </div>
    )
}