// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBq6nUxAMH2Z6Jvh0yxVwlRYHr8mn0ZHdE",
  authDomain: "guardiansofthesparknews.firebaseapp.com",
  projectId: "guardiansofthesparknews",
  storageBucket: "guardiansofthesparknews.appspot.com",
  messagingSenderId: "981420128908",
  appId: "1:981420128908:web:26950343a829e8edfe59a9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const news = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

const db = {news: news, auth: auth, storage: storage}
export default db;