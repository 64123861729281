import { Col, Container, Row } from 'react-bootstrap';
import '../assets/css/About.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import PageTitle from '../helpers/PageTitle';

import design from '../assets/img/part6-main.png';
import design2 from '../assets/img/part4.png';
const team = [
    {id: 0, name: 'Mile Gramatikov', position: 'Chief Executive Officer', socials: {linkedin: 'https://www.linkedin.com/in/mile-gramatikov-14869b1a4', twitter: 'https://x.com/milegramatikovv'}, img: '/static/team/mile.png', description: 'CEO & Founder of Battle For Giostone and M-Tech Entertainment <br/> CTO & Head of Business Development at TopDollarHub (Affiliate Network) <br/> Advisor at Deck of Cosmos'},
    {id: 1, name: 'Tome Dzidzev', position: 'Producer', socials: {linkedin: 'https://www.linkedin.com/in/tome-dzidzev-5153172ab', twitter: 'https://x.com/TomeDzi'}, img: '/static/team/tome.png', description: 'Advisor and producer at Deck Of Cosmos <br/> Caster at Battle For Giostone'},
    {id: 2, name: 'Ana Toseva', position: 'Chief Technology Officer', socials: {linkedin: 'https://www.linkedin.com/in/ana-toseva-676093227', twitter: 'https://x.com/atosevaa'}, img: '/static/team/ana.png', description: 'CTO & CO-Founder of Battle For Giostone'},
    {id: 3, name: 'Dimitar Solakov', position: 'Chief Operating Officer ', socials: {linkedin: 'https://www.linkedin.com/in/dimitar-solakov-87634122a/', twitter: 'https://x.com/solakovbfg'}, img: '/static/team/mitko.png', description: 'CO-Founder of Battle For Giostone <br/> COO at TopLeadsCPA (Affiliate Network)'},
]
function AboutPage(){

    function goTo(link){
        window.open(link, '_blank');
    }

    return(
        <section className='aboutSection'>
            <PageTitle title="About" />
            <div className='container__about pb-5'>
                
                <img className='img-des__about' src={design} alt='design' />
                <img className='img-des__aboutT' src={design2} alt='design' />
                
                <Container className='container_about_header' style={{position:'relative', zIndex: '5'}}>
                    {/*<h6 className="subtitle__gameplay" style={{paddingTop: '0', paddingBottom: '0', textAlign: 'start'}}>ABOUT US</h6>*/}
                    <h1 style={{textAlign: 'start', color: 'white', padding: '0'}}>MEET THE TEAM</h1>
                    <p className="p_heading__gameplay" style={{paddingTop: '.5rem', textAlign: 'start'}}>
                        Step into the future of Web3 gaming with us! Our innovative team is crafting extraordinary experiences that blend creativity and technology. Join our mission to redefine gaming and be a key player in this exciting evolution.
                    </p>
                    
                    <div className='team-section'>
                        {team && team.map(member => 
                            <Row key={member.id} className={member.id % 2 === 0 ? 'start__about' : 'end__about'}>
                                <Col xs={12} sm={5} md={4} lg={4} xl={4} xxl={4} className={member.id % 2 === 0 ? 'start_col__about' : 'end_col__about'}>
                                    <div class="alien-frame">
                                        <div style={member.id % 2 === 0 ? {transform: 'scaleX(-1)'} : {}} className='img-galactic'>
                                            <img class="alien-image" src={member.img} alt="team" />
                                        </div>
                                    </div>
                                </Col>
                                <Col className={member.id % 2 === 0 ? 'start_col_text__about' : 'end_col_text__about' }>
                                    <h1>{member.name}</h1>
                                    <div className='flex-center__about' style={member.id % 2 === 0 ? {} : {flexDirection: 'row-reverse'}}>
                                        <h6 className="subtitle__about">{member.position}</h6>
                                        <div className="icon-menu icon-menu-hover" >
                                            <FontAwesomeIcon onClick={() => goTo(member.socials.linkedin)} style={{padding: '.6rem'}} size="xs" icon={faLinkedinIn} />
                                        </div>
                                        <div className="icon-menu icon-menu-hover" >
                                            <FontAwesomeIcon onClick={() => goTo(member.socials.twitter)} style={{padding: '.6rem'}} size="xs" icon={faTwitter} />
                                        </div>
                                    </div>
                                    <p className='p__about_desc' style={member.id % 2 === 0 ? {} : {textAlign: 'end'}} dangerouslySetInnerHTML={{ __html: member.description}}></p>
                                </Col>
                            </Row>    
                        )}
                    
                    </div>
                </Container>
            </div>
        </section>
    )
}

export default AboutPage;