import { useState } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import arrowR from '../../assets/img/arrow-right.png';
import arrowL from '../../assets/img/arrow-left.png';
import Fade from '@mui/material/Fade';

const heroes = [
    {id: 0, name: 'GAIALITH', planet: 'Cercilles', img: '/static/heroes/titan.png', planetImg: '/static/planets/cercilles.png'},
    {id: 1, name: 'LEMIAN', planet: 'Lebethorn', img: '/static/heroes/elf.png', planetImg: '/static/planets/lebethorn.png'},
    {id: 2, name: 'WISELIAN', planet: 'Avaress', img: '/static/heroes/kotl.png', planetImg: '/static/planets/Avaress.png'},
    {id: 3, name: 'L.A.R', planet: 'Nexoria 003X', img: '/static/heroes/robot.png', planetImg: '/static/planets/003X.png'},
    {id: 4, name: 'KING HODRICK', planet: 'Dalmon', img: '/static/heroes/skeleton.png', planetImg: '/static/planets/dalmon.png'},
    {id: 5, name: 'DARFIN', planet: 'X', img: '/static/heroes/dark.png', planetImg: '/static/planets/X.png'},
]
function HeroesNormal(){
    const [selected, changeSelected] = useState(0);
    const [change, changeChange] = useState(true);

    function goLeft() {
        changeChange(false)
        setTimeout(() => {
            let temp = selected;
            if(selected === 0){
                changeSelected(heroes.length-1)
                
                changeChange(true)
                
            }else{
                changeSelected(temp-1)
                changeChange(true)
            }
        }, 300)

        
    }

    function goRight() {
        changeChange(false);
        setTimeout(() => {
        let temp = selected;
            if(selected === heroes.length-1){
                changeSelected(0)
                changeChange(true)
                
            }else{
                changeSelected(temp+1)
                changeChange(true)
            }
        }, 300)
    }

    return(
        <section className="main-heroes" style={{position: 'relative', flex: '1'}}>
            <div className="onTop"/>

            <div style={{position: 'relative', zIndex: 3, paddingTop: '2rem'}}>
                
                <h6 className="subtitle__gameplay">HEROES</h6>
                <h1 className="title__gameplay"> Meet The Guardians </h1>
                {/*  <p className="p_gameplay" style={{width: '100%', paddingTop: '.3rem'}}>Tailor your gameplay to your liking by choosing one of many distinct characters.</p>
                 */}
                <img onClick={() => {goRight()}} className="arrowL__heroes" src={arrowL} alt="arrow-l"/>
                <Container maxWidth="lg" className="ps-3 pt-5 pb-5 conteiner_heroesnornmal">
                    <Grid className="grid_heroesnormal" container spacing={2}>
                        <Grid item xs={12} sm={7} md={8}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Fade  in={change}>
                                    <img className="img_heronormal" style={{width: '70%'}} src={process.env.PUBLIC_URL + heroes[selected]?.img} alt='hero-img'/>  
                                </Fade>
                            </div>
                        </Grid>
                        <Grid style={{display: 'flex'}} item xs={12} sm={5} md={4}>
                            <Fade in={change}>
                                <div className="guardians_desc" style={{flex: 1}}>
                                        <h3 className="h3_guard_desc">{heroes[selected]?.name}</h3>
                                        <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: '2rem'}} >
                                            <img className="img_heronormal1" style={{width: '100%'}} src={heroes[selected]?.planetImg} alt="planet"/>
                                        </div>
                                        <p className="p_guard_desc"><span className="text-gray">Planet:</span> {heroes[selected]?.planet}</p>
                                    
                                </div>
                            </Fade>
                        </Grid>
                    </Grid>
                </Container>
                <img onClick={() => {goLeft()}}  className="arrowR__heroes" src={arrowR} alt="arrow-r"/>
            </div>

            <div className="onBottom"/>
        </section>
    )
}

export default HeroesNormal;