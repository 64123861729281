import { useSelector, useDispatch} from 'react-redux';
import { Button, TextField, Grid} from '@mui/material';
import { useState, useEffect } from 'react';
import { setIskraCred, setUserInfoIskra, setUserWalletIskra } from '../../redux/reducers/userIskraReducer';
import { setUser, setToken} from '../../redux/reducers/userQuestingReducer';
import axiosInstance from '../../redux/api';
import iskra from '../../assets/img/iskra-logo.webp';
import metamask from '../../assets/img/metamask-logo.webp';
import base from '../../assets/img/base-logo.png';

import { IskraSDK, LoginOptionBuilder } from '@iskraworld/iskra-sdk';
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function WalletSettings({setSuccess, setError}){
    const {wallet, account} = useSelector(state => state.userIskra);
    const {user} = useSelector(state => state.userQuesting);

    const [provider, setProvider] = useState(null);
    const dispatch = useDispatch();
    
    const getPrivateKey = async () => {
        const p = await IskraSDK.instance().getWalletProviderWithId("iskra");
        await p.provider
        .request({ method: 'eth_getTransactionCount',
            params: [wallet?.walletAddress, 'latest']
         })
        .then(res => {
            console.log(res)
        })
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log('Please connect to MetaMask.');
          } else {
            console.error(err);
          }
        });

    }
    
    useEffect(() => {
        async function getProvider() {
            if(IskraSDK?.instance()?.isInitialize() && !provider && wallet){
                const { provider, error } = await IskraSDK.instance().getWalletProviderWithId("iskra");

                if(!error){
                    setProvider(provider);
                }
            }
        }
        getProvider();
    }, [provider, wallet])


    provider?.on('accountsChanged', handleAccountsChanged);

    function handleAccountsChanged(res) {
        console.log(res)
    }

    async function connect() {
        const p = await IskraSDK.instance().getWalletProviderWithId("iskra");

        await p.provider.request({ method: 'eth_requestAccounts' })
        .then(handleAccountsChanged)
        .catch((err) => {
        if (err.code === 4001) {
            console.log('Please connect to MetaMask.');
        } else {
            console.error(err);
        }
        });
    }

    const syncWallet =  async () => {
        try{
            let fd = new FormData();
            fd.append('user_id', account?.userId);
            fd.append('access_token', account?.accessToken);
            const res = await axiosInstance.post('auth/web3-verify' , fd);
           
            if(res?.data?.data?.token){
                dispatch(setToken(res.data?.data?.token))
    
                const result1 = await axiosInstance.get('auth/me');
                if(result1.data?.data){
                    dispatch(setUser(result1.data?.data));
                    setSuccess('Wallet sync successfull');
                }
            }
        }catch{
                setError('Failed to sync wallet')
        }
    }

    function createWallet() {
        IskraSDK.instance().getOrConnectWallet((async (data, error) =>
            {
                console.log(data)
                if (error != null)
                {
                    setError(error.message);
                return;
                }
                dispatch(setUserWalletIskra(data));
                
                syncWallet();
                setSuccess('Iskra wallet created');
            }));
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const copyContent = (text) => {
        try{
            navigator.clipboard.writeText(text);
            setSuccess('Wallet address copied to clipboard')
        }catch{}
    }

    const logInIskra = async () => {
        //const loginOption = new LoginOptionBuilder().enableIskraWallet().build();
        const loginOption = new LoginOptionBuilder();
        
        await IskraSDK.instance().login(loginOption, async (data, error) => {
          if (error != null) {
            setError(error.message)
            return;
          }
          dispatch(setIskraCred({userId: data.userId, accessToken: data.accessToken}))
          await IskraSDK.instance().getAuth((data, error) => {
            if (error != null) {
              setError(error.message)
              return;
            }
            dispatch(setUserInfoIskra(data));
          });
          await IskraSDK.instance().getWallet((data, error) => {
            if (error != null) {
              return;
            }
              dispatch(setUserWalletIskra(data));
          });
          
        });
      }

    console.log(account)

    return(
        <div>
            {(wallet) && user?.wallet_address &&
            <div className="pt-4">
                {wallet?.walletProviderId &&
                    <div className="pt-1">
                        {/* 
                        <p className="p_settings">Make sure the wallet {wallet?.walletAddress} is imported to the Wallet Provider you want to change to.</p>
                        */}
                        <Grid container>
                            <Grid item xs={6}>
                                <h6 className="profile_label_acc">Wallet Provider</h6>
                                {wallet.walletProviderId === 'iskra' &&
                                    <>
                                    <img className="provider_settings" src={iskra} alt="provider" />
                                    <span className="mt-1">{capitalizeFirstLetter(String(wallet.walletProviderId))}</span>
                                    </>
                                }
                                {wallet.walletProviderId === 'metamask' &&
                                    <>
                                    <img className="provider_settings" src={metamask} alt="provider" />
                                    <span className="mt-1">{capitalizeFirstLetter(String(wallet.walletProviderId))}</span>
                                    </>
                                }
                                {wallet.walletProviderId === 'coinbase' &&
                                    <>
                                    <img className="provider_settings" src={base} alt="provider" />
                                    <span className="mt-1">{capitalizeFirstLetter(String(wallet.walletProviderId))}</span>
                                    </>
                                }
                            </Grid>
                            <Grid style={{display: 'flex', justifyContent: 'flex-end'}} item xs={6}>
                                {/* <Button onClick={() => {connect()}} variant="contained">Change Wallet</Button>
                                
                                <Button onClick={() => {getPrivateKey()}} variant="contained">Export key</Button>
                                */}
                            </Grid>
                        </Grid>
                    </div>
                }
                <TextField 
                    InputProps={{ readOnly: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <ContentCopyIcon onClick={() => copyContent(wallet?.walletAddress)} className="icon_input" />
                            </InputAdornment>
                        ),
                    }} 
                    sx={{color: 'white'}} 
                    value={wallet?.walletAddress} 
                    size="small" fullWidth label="Wallet Address" 
                    className="input_quest mt-5 mb-5" type="text" 
                />
            </div>
            }

            {(wallet && !user?.wallet_address && account) &&
              <>
              <div className="pt-5 pb-5">
                  <Grid container>
                      <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
                          <Button onClick={() => {syncWallet()}} variant="contained">Sync Wallet</Button>
                      </Grid>
                  </Grid>
              </div>
          </>
            }

            {(!wallet && account) && 
            <>
                <div className="pt-4">
                    <h6>Create Wallet</h6>
                    <p className="p_settings">This wallet will be connected to your account and can't be changed.</p>
                    <Grid container>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid style={{display: 'flex', justifyContent: 'flex-end'}} item xs={6}>
                            <Button onClick={() => {createWallet()}} variant="contained">Create Wallet</Button>
                        </Grid>
                    </Grid>
                </div>
            </>}

        </div>
    )
}