import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

import axiosInstance from '../../redux/api';
import ConfirmDelete from './Confirmation';


export default function TableQuests({meta, loading, quests, setQuests, setSuccess, setError, setLoading, setQuestMeta}){

    const [paginationModel, setPaginationModel] = React.useState({
      page: meta?.current_page ? meta.current_page - 1 : 0,
      pageSize: meta?.per_page ? meta.per_page  : 50
    });
    const rowCount =  meta?.total ? meta.total : quests.length;

    const [rowModesModel, setRowModesModel] = React.useState({});
    const [deleteShow, setDelete] = React.useState(false);
    const [selected, setSelected] = React.useState(null);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'reward_id', headerName: 'Reward ID', width: 170, editable: true},
        { field: 'reward_name', headerName: 'Reward name', width: 250, editable: true },
        { field: 'credits_amount', headerName: 'Credits', width: 90, type: 'number', editable: true },
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      
            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  icon={<SaveIcon />}
                  label="Save"
                  sx={{
                    color: 'primary.main',
                  }}
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  icon={<CancelIcon />}
                  label="Cancel"
                  className="textPrimary"
                  onClick={handleCancelClick(id)}
                  color="inherit"
                />,
              ];
            }
      
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
              />,
            ];
          },
        },
      ];

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
  
    const handleEditClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
  
    const handleSaveClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
  
    const handleDeleteClick = (id: GridRowId) => () => {
      setDelete(true);
      setSelected(quests.find(x => {return x.id === id}))
      //setQuests(quests.filter((row) => row.id !== id));
    };

    const deleteElement = async () => {
      if(selected){
        try{
          await axiosInstance.delete('admin/rewards/'+selected?.id);
          setQuests(quests.filter((row) => row.id !== selected?.id));
          setSelected(null);
          setDelete(false);
          setSuccess('Quest deleted successfully');
        }catch{
          setError('Failed to delete');
          setDelete(false);
        }
      }
    }
  
    const handleCancelClick = (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    };
  
    const processRowUpdate = async (newRow: GridRowModel) => {
      const updatedRow = newRow;
      const oldRow = quests.find(x => {return x.id === newRow?.id});
     
      try{
        const str = "?reward_id="+updatedRow?.reward_id+'&reward_name='+updatedRow?.reward_name+'&credits_amount='+updatedRow?.credits_amount;
        const result = await axiosInstance.put('admin/rewards/'+newRow?.id+str);
      
        if(result?.data?.data){
            setSuccess('Reward '+result?.data?.data?.reward_id+ ' updated.');
            return result?.data?.data;
        }else{
            return oldRow;
        }
      }catch{
        return oldRow;
      }
    };
  
    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };

    const handlePaginationModelChange = async (newPaginationModel: GridPaginationModel) => {
      setLoading(true);
      try{
        const result = await axiosInstance.get('admin/rewards?page='+newPaginationModel.page+1);
        if(result?.data?.data){
          setQuests(result.data.data);
          setQuestMeta(result?.data?.meta);
        }
        
        setLoading(false);
        setPaginationModel(newPaginationModel);
      }catch{
        setLoading(false);
        setPaginationModel(newPaginationModel);
      }
    };

    return(
        <div style={{ height: 400, width: '100%' }}>
        <DataGrid
            rows={quests}
            columns={columns}
            editMode="row"    
            pageSizeOptions={[(meta?.per_page ? meta?.per_page : 50)]}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            paginationMode="server"
            paginationModel={paginationModel}
            rowCount={rowCount}
            loading={loading}
            onPaginationModelChange={handlePaginationModelChange}
        />
        <ConfirmDelete setShowAcc={setDelete} deleteElement={deleteElement} showAcc={deleteShow} setSuccess={setSuccess} selected={selected} />
        </div>
    )
}