 import GamePlay from "../components/Home/GamePlay";
import Sponsors from "../components/Home/Sponsors";
import Roadmap from "../components/Home/Roadmap";
import Footer from "../layouts/Footer";
import Token from "../components/Home/Token";
import TokenDetails from "../components/Home/TokenDetails";
import Typewriter from "typewriter-effect";
import backvideo from '../assets/img/backvideo.mp4';
import ThirdwebBtn from "../helpers/ThirdwebBtn";
import { useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import PageTitle from '../helpers/PageTitle';

function HomePage({setSuccess, setError}){
    const {account} = useSelector(state => state.userIskra);
    const {user} = useSelector(state => state.userQuesting);

    const navigate = useNavigate();
    const goTo = (link) => {
        window.open(link, "_blank");
    }

    return(
        <>
            <PageTitle title="" />
            <section className="sticky-header__home">
                <div className="header-section__home">
                    <video id="background-video" loop preload="auto" muted playsInline autoPlay>
                        <source src={backvideo} type="video/mp4" />
                    </video>
                    <div className="video-blacked" />
                </div>
                <div className="content-header__home">
                    <div>
                        {/*<img src={logoText} alt='logo' className="logo-header" />*/}
                        <h1 className="h1-header__home social">
                        <Typewriter
                        onInit={(typewriter)=> {
                        typewriter
                        .changeDelay(30)
                        .typeString("GUARDIANS OF THE SPARK")
                        .start()
                        document.getElementsByClassName('social')[0].classList.add('visible')
                        }}
                        />
                        </h1>
                        <h6 className="h6-header_home social">
                        <Typewriter
                        onInit={(typewriter)=> {
                        typewriter
                        .pauseFor(2000)
                        .changeDelay(30)
                        .typeString("New era of web3")
                        .pauseFor(1000)
                        .deleteChars(4)
                        .typeString("Web3 Tower Defense Game that allows players to earn endlessly,")
                        .typeString(" Forge your own guardian and enter a world with endlessly possibilities")
                        .pauseFor(1000)
                        .typeString(" !!")
                        .start()
                        document.getElementsByClassName('social')[1].classList.add('visible')
                        }}
                        />
                        </h6>
                    </div>
                    <div className="buttons-header__home">
                        {!account &&
                            <ThirdwebBtn label="Sign Up" setSuccess={setSuccess} setError={setError} />
                        }
                        {account && !user?.username &&
                            <div onClick={() => {navigate('/user/settings/game-account')}} className="play-btn__home">SET GAME ACCOUNT</div>
                        }
                        {account && user?.username &&
                            <div onClick={() => {navigate('/user/airdrop')}}  className="play-btn__home">AIRDROP</div>
                        }
                        <a href="https://playgots.xyz/static/GOTS_CBT_V1.1_Final.rar" style={{textDecoration: 'none'}} download="GOTS_CBT_V1.1_Final.rar" className="join-btn__home">DOWNLOAD</a>
                        
                    </div>
                    
                </div>
            </section>
            <section className="grid--after__home">
                <div className="inside-grid__home">
                    <div className="top-break__home">
                        <div className="top-breaker-t__home" />
                        <div className="top-breaker-m__home" />
                        <div className="top-breaker-b__home" />
                    </div>
                    
                  
                    <div className="inner-section__home_plus">
                        <div className="css-ishp"></div>
                    </div>
                    {/* GAMEPLAY */}
                    <GamePlay></GamePlay> 
                      
                    {/*
                    <div className="inner-section__home_plus_reverse">
                        <div className="css-ishp_reverse"></div>
                    </div>

                   <Heroes></Heroes>*/}

                    <div style={{height: '6rem', position: 'relative'}}>
                        <Token></Token>
                    </div>

                    <div className="token_pre__home">
                        <TokenDetails></TokenDetails>
                    </div>
                    

                    <Roadmap/>

                    
                    <div className="inner-section__main__home" style={{position: 'relative'}}>
                        <Sponsors></Sponsors>
                    </div>
                </div>
                
                <Footer/>
            </section>
        </>
    )
}

export default HomePage;