import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: null,
    user: {
        id: -1,
        credits: 0,
        lastClaimed: '05/25/2024 12:00:00'
    }
};

const userQuestingSlice = createSlice({
    name: "userQuesting",
    initialState: initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setWalletUser: (state, action) => {
            state.user.wallet = action.payload;
        },
        setCredits:(state, action) => {
            state.user.credits = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        incrementCredits: (state) => {
            state.user.credits += 1;
        },
        logOutUser: (state) => {
            state.token = null;
            state.user = {
                id: -1,
                credits: 0,
                lastClaimed: '05/25/2024 12:00:00'
            };
        }
    }
});

export const {setToken, setUser, incrementCredits, setWalletUser, logOutUser, setCredits} = userQuestingSlice.actions;

export default userQuestingSlice.reducer;