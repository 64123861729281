import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TextField } from '@mui/material';
import axiosInstance from '../redux/api';
import { useSelector, useDispatch} from 'react-redux';
import { setUser } from '../redux/reducers/userQuestingReducer';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function SetEmail({showAddEmail, setShowAddEmail, setSuccess}){
    const [email, setEmail] = useState('');
   
    const [error, setErrors] = useState('');

    const matches = useMediaQuery('(max-width:600px)');

    const fullscreen = matches;

    const {user} = useSelector(state => state.userQuesting);

    const handleClose = () => setShowAddEmail(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        setErrors('');
    }, [email])
    
    const validateEmail = (email1) => {
        return String(email1)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };


    const setProfile  = async () => {
        if(email){
            if(validateEmail(email)){
                setErrors('');
                try{
                    let str = '?email='+email;
            
                    const res = await axiosInstance.put('/users/'+user?.id+str);

                    if(res?.data?.data){
                        dispatch(setUser(res?.data?.data));
                        setSuccess('Email added');
                        setShowAddEmail(false);
                    }
                }catch(err){
                    if(err?.response?.data?.message){
                        setErrors(err?.response?.data?.message)
                    }
                }
            }else{
                setErrors('Incorrect email format');
            }
        }else{
            setErrors('Fill in the form');
        }
    }
  
    return (
        <Modal
          fullscreen={fullscreen}
          show={showAddEmail}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title style={{textAlign: 'center', width: '100%'}}>Set account email to continue</Modal.Title>
          </Modal.Header>
          <Modal.Body className="body_setacc">
            <div className="form_questing ps-0 pe-0">
                <TextField 
                    id="input_username_settings" 
                    sx={{color: 'white'}} 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} 
                    size="small" fullWidth label="Email" 
                    className="input_quest" type="email" 
                />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{width: '100%'}} onClick={() => setProfile()}  variant="primary">Update</Button>
            <div className="error_form">
                {error !== '' && <span>{error}</span>}
            </div>
          </Modal.Footer>
        </Modal>
    );
}