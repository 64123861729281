import HeroesNormal from "../components/Home/HeroesNormal";
import PageTitle from '../helpers/PageTitle';

export default function Guardians(){
    return(
        <>
        <PageTitle title="Guardians" />
        <HeroesNormal></HeroesNormal>
        </>
    )
}