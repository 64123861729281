import {useEffect, useState, useCallback} from 'react';
import axiosInstance from '../../redux/api';
import FacebookCircularProgress from '../../helpers/FacebookCircularProgress';
import { setUser } from '../../redux/reducers/userQuestingReducer';
import { useDispatch} from 'react-redux';

export default function Quests({user, setError, setSuccess}){
    const [noQuests, setNoQuests] = useState(true);
    const [loading, setLoading] = useState(true);
    const [quests, setQuests] = useState(null);
    const dispatch = useDispatch();

    const goToLink = (link) => {
        window.open(window.origin + '/user/airdrop/'+ link, '_blank');
        //navigate('/user/airdrop/'+link);
    }

    const checkComplete = async (rewId) => {
        try{
            let fd = new FormData()
            fd.append('reward_id',rewId)
            fd.append('dry_run',true)
            const res = await axiosInstance.post('claim-credits', fd)
            return res?.data?.pagination?.total_count > 0
        }catch{
            return false;
        }
    }

    const getQuests = useCallback(
      async () => {
        if(quests === null){
            try{
                let res = await axiosInstance.get('rewards?page=1');
                let res1 = await axiosInstance.get('me/rewards');
               
                let q = res?.data?.data;
                let me = res1?.data?.data;
                if(q?.length){
                    if(me?.length){
                        let temp = q.filter(x => {
                            return me.filter(y => {
                                return y.reward_id !== x.reward_id
                            }).length > 0
                        })
                        setQuests(temp)
                    }else{
                        setQuests(q);
                    }
                }
            }catch{
                setLoading(false);
            }
        }else{
            setLoading(false);
        }
      },
      [quests],
    )

    useEffect(() => {
        getQuests();
    }, [getQuests])

    const claimReward = async (rewId) => {
        try{
            let fd = new FormData()
            fd.append('reward_id',rewId)
            const res = await axiosInstance.post('claim-credits', fd)
           
            if(res.data?.success === 'no'){
                setError("Quest is not completed for " + user?.email)
            }
            if(res.data?.data?.credits >= 0){
                setSuccess('Rewards claimed');
                dispatch(setUser(res.data?.data));
                let index = quests.findIndex(x => {return x.key === rewId});
                if(index !== -1){
                    setQuests(quests.splice(index, 1))
                }
            }
        }catch(err) {
            if(err?.response?.data?.message){
                setError(err?.response?.data?.message)
            }
        }
    }

    return(
        <div className="quest_cont">
            {noQuests &&
                <div className="no_t_quests">No available airdrop quests at the moment</div>
            }
            {!noQuests && loading &&
                <div className="display_center">
                    <FacebookCircularProgress />
                </div>
            }
            {!noQuests && !loading && quests?.length > 0 && quests.map(q =>
                <div className="quest_link_row" key={q.id}>
                    <div className="quest_name">{q.reward_name}</div>
                    <div className="display_flex_quest_btns">
                        <div className="quest_btn" onClick={() => goToLink(q.reward_id+'/'+q.reward_name.toLowerCase().replaceAll(' ', '-'))}>Enroll +{q.credits_amount} SP </div>
                        <div className="quest_btn" onClick={() => claimReward(q.reward_id)}>Claim Reward</div>
                    </div>
                </div>
            )}
            {!noQuests && !loading && quests?.length === 0 &&
                <div className="no_t_quests">No available airdrop quests at the moment</div>
            }
        </div>
    )
}