import { IskraSDK, LoginOptionBuilder } from '@iskraworld/iskra-sdk'
import {Button} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { logOutIskra, setIskraCred, setUserInfoIskra, setUserWalletIskra } from '../redux/reducers/userIskraReducer';
import { setUser, setToken, logOutUser} from '../redux/reducers/userQuestingReducer';

import axiosInstance from '../redux/api';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import QuizIcon from '@mui/icons-material/Quiz';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
    backgroundColor: '#0E1524',
    borderRadius: 0,
    padding: 0,
    border: '1px solid rgb(86, 139, 236)',
  },
});

const UserMenu = ({goTo, logOut}) => {
  return(
    <Box sx={{ width: '100%', maxWidth: 300, padding: 0}}>
      <List className="list_menu_user" sx={{padding: 0}} >

      <ListItem onClick={() => goTo('/user/settings/game-account')}  sx={{padding: 0}} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <SportsEsportsIcon />
            </ListItemIcon>
            <ListItemText primary="Game Account" />
          </ListItemButton>
        </ListItem>
        
        <ListItem onClick={() => goTo('/user/airdrop')}  sx={{padding: 0}} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <QuizIcon />
            </ListItemIcon>
            <ListItemText primary="Airdrop" />
          </ListItemButton>
        </ListItem>

        <ListItem onClick={() => goTo('/user/settings')}  sx={{padding: 0}} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem onClick={() => logOut()} sx={{padding: 0}} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItemButton>
        </ListItem>
      </List>
  </Box>
  )
}

function ThirdwebBtn({setSuccess, setError, label}){
    const {user} = useSelector(state => state.userQuesting);
    const {account} = useSelector(state => state.userIskra);
    const [userMenu, setUserMenu] = useState(false);

    const navigator = useNavigate(process.env.PUBLIC_URL);
    const dispatch = useDispatch();
    
    const handleTooltipClose = () => {
      setUserMenu(false);
    };

    const goTo = (link) => {
      navigator(link);
      handleTooltipClose();
    }
  
    const logOut = () => {
      try{
        IskraSDK.instance().logout(async (error) => {
          if (error != null) {
            return;
          } 

          try{
            await axiosInstance.get('auth/logout');
          }catch{}

          dispatch(logOutIskra());
          dispatch(logOutUser());
          setSuccess("Logged out successfully")
        });
      }catch{
        dispatch(logOutIskra());
        dispatch(logOutUser());
        setSuccess("Logged out successfully")
      }
    }

    const connectDB = async (userId, accessToken) => {
      try{
        let fd = new FormData();
        fd.append('user_id', userId);
        fd.append('access_token', accessToken);
        const res = await axiosInstance.post(process.env.REACT_APP_VERIFY_URL, fd)

        if(res?.data?.data?.token){
          dispatch(setToken(res.data?.data?.token))

          const result1 = await axiosInstance.get('auth/me');
          if(result1.data?.data){
              dispatch(setUser(result1.data?.data));
              setSuccess('Log in successfull');
          }
        }
      }catch{
        setError('Account cannot sync');
        //dispatch(logOutIskra());
        //dispatch(logOutUser());
      }
    }

    const logInIskra = async () => {
      //const loginOption = new LoginOptionBuilder().enableIskraWallet().build();
      const loginOption = new LoginOptionBuilder();
      
      await IskraSDK.instance().login(loginOption, async (data, error) => {
        if (error != null) {
          setError(error.message)
          return;
        }
        dispatch(setIskraCred({userId: data.userId, accessToken: data.accessToken}))
        await IskraSDK.instance().getAuth((data, error) => {
          if (error != null) {
            setError(error.message)
            return;
          }
          connectDB(data.userId, data.accessToken);
          dispatch(setUserInfoIskra(data));
        });
        await IskraSDK.instance().getWallet((data, error) => {
          if (error != null) {
            return;
          }
            dispatch(setUserWalletIskra(data));
        });
        
      });
    }

    return(
      <>
        {label !== 'Sign Up' &&
        <>
          {(!account && user.id === -1) &&
            <Button className="join-btn__home btn_login" onClick={() => {logInIskra()}}>Log in</Button>
          }

          {(account || user.id === 1) &&
            <div>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <CustomWidthTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    TransitionComponent={Zoom}
                    onClose={handleTooltipClose}
                    open={userMenu}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                    <UserMenu
                    goTo={goTo}
                    logOut={logOut}
                    />
                    }
                  >
                    <div className={userMenu ? "icon-menu-user icon-menu-user-selected" : "icon-menu-user"} onClick={() => setUserMenu(!userMenu)} >
                      <img className="create_btn_img" src={user?.profile_picture ? user.profile_picture : '/static/defaultProfilePicture.png'} alt="provider" />
                        {userMenu ? <ExpandLessIcon size="md" className="ms-1" /> : <ExpandMoreIcon size="md" className="ms-1" />}
                    </div>
                  </CustomWidthTooltip>
                </div>
                </ClickAwayListener>
            </div>
          }
          </>
        }
        {label === 'Sign Up' &&
          <div onClick={() => {logInIskra()}} className="play-btn__home">SIGN UP</div>
        }
      </>
    )
}

export default ThirdwebBtn;