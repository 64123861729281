import { Col, Row } from 'react-bootstrap';
import roadmap from '../../assets/img/roadmap-text.png';
import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAnglesRight, faCheck, faMinus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Typewriter from "typewriter-effect";
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

const years = [2023, 2024];

const roadmaps = [ 
    {id: '2023', seasons: [ 
       
        {name: 'Q4 2023', complete: 'true', tasks: [
            {name: 'Team Formation', complete: true},
            {name: 'Branding', complete: true},
            {name: 'Seed Round Funding', complete: true},
        ]}
    ]},
    {id: '2024', seasons: [ 
        {name: 'Q1 2024', complete: 'true', tasks: [
            {name: 'Game Development', complete: true},
            {name: 'Tokenomics Design', complete: true},
            {name: 'Website Development', complete: true},
        ]}, 
        {name: 'Q2 2024', complete: 'pending', tasks: [
            {name: 'Website Launch', complete: true},
            {name: 'Social Media Launch', complete: true},
            {name: "Beta Testing", complete: true},
            {name: '$Spark Airdrop', complete: 'pending'},
            {name: 'Private Round Funding', complete: false},
        ]}, 
        {name: 'Q3 2024', complete: 'false', tasks: [
            {name: "$SPARK Token Launch", complete: false},
            {name: '$SPARK IGO', complete: false},
            {name: 'Initial NFT Offering', complete: false},
            {name: 'Initial LP Creation', complete: false},
            {name: 'Alpha Release', complete: false},
            {name: 'CEX Listings', complete: false}
        ]}, 
        {name: 'In a new Galaxy', complete: 'false', tasks: [
            {name: '. . .', complete: "tobe"}
        ]}
    ]}
]

function Roadmap(){

    const [selected, changeSelected] = useState(2024)
    const matches = useMediaQuery('(max-width:600px)');

    function getPrev(){
        let index = years.findIndex(r => r === selected);
        
        if(index !== -1){
            if(index-1 !== -1){
                changeSelected(years[index-1])
            }
        }
    }

    function getNext(){
        let index = years.findIndex(r => r === selected);
        if(index !== -1){
            if(index+1 !== years.length){
                changeSelected(years[index+1])
            }
        }
    }
    return(
        <section className="roadmap_section">
            
            <div className='roadmap_container_s'>
                <Row>
                    <Col>
                        {/** 
                        <h6 style={{textAlign: 'start', color: 'rgba(255, 255, 255, 0.8)', paddingTop: '0rem'}} className="subtitle__gameplay ps-2" >PROGRESS</h6>
                        */}
                        <div className='div_img__roadmap'>
                            {/** 
                            <img src={roadmap} className='roadmap-img' alt='roadmap' />
                             <FontAwesomeIcon className='icons__roadmap_np ps-2' onClick={() => getPrev()} icon={faAnglesLeft} />
                            <FontAwesomeIcon className='icons__roadmap_np' onClick={() => getNext()} icon={faAnglesRight} />
                            */}
                            <h1 className="h1-header__home social1">
                            <Typewriter
                            onInit={(typewriter)=> {
                            typewriter
                            .changeDelay(75)
                            .typeString("ROADMAP")
                            .start()
                            document.getElementsByClassName('social1')[0].classList.add('visible')
                            }}
                            />
                            </h1>
                           
                        </div>
                    </Col>
                    <Col className='col_roadmap_years'>
                        {years && years.map(y => 
                            <div onClick={() => changeSelected(y)} className={y === selected ? 'div_roadmap_years_selected' : 'div_roadmap_years'} key={y}>
                                {y}
                            {y === selected &&
                                <div className='selected_features__roadmap'>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            }
                            </div>
                        )}
                    </Col>
                </Row>
                {!matches &&            
                <Row className='pt-5 ps-2 pe-2 main_row_milestones__roadmap'>
                    <Row className='p-xs-4 row_milestones__roadmap'>
                        {
                            roadmaps.filter(x => {return x.id.toString() === selected.toString()}).map(x => 
                                <Fragment key={x.id}>
                                {x.seasons && x.seasons.map(s => 
                                    <Col key={s.name}>  
                                        <h6 className={s.complete === 'pending' ? 'season_name__roadmap_pending season_name__roadmap' : 'season_name__roadmap'} style={s.complete === 'true' ? {} : {color: '#ffffff49'}}>{s.name}</h6> 
                                    </Col>
                                )}
                                </Fragment>
                            )
                        }
                    </Row>
                    <div className='div_container_dashes__roadmap'>
                        <div className='div__dashes__roadmap'></div>
                    </div>
                    <Row className='pt-4 row_milestones__roadmap'>
                        {
                            roadmaps.filter(x => {return x.id.toString() === selected.toString()}).map(x => 
                                <Fragment key={x.id}>
                                {x.seasons && x.seasons.map(s => 
                                    <Col key={s.name}>  
                                        <div className='circle-icon__roadmap' style={s.complete === 'true' ? {background: 'green'} : {}}>
                                            {s.complete === 'true' && 
                                                <FontAwesomeIcon size='xs' icon={faCheck} />
                                            }
                                            {s.complete === 'pending' && 
                                                <FontAwesomeIcon size='xs' className='fa-spin' icon={faSpinner} color='white' />
                                            }
                                        </div>
                                        <div className='tasks_container__roadmap'>
                                            {s.tasks && s.tasks.map(t => 
                                                <div className='tasks_text__roadmap' key={t.name}>
                                                    {t.complete === true &&
                                                        <FontAwesomeIcon style={{color: 'white'}} className='pe-2' size='sm' icon={faCheck} />
                                                    }
                                                    {(t.complete === false || t.complete === 'pending')  &&
                                                        <FontAwesomeIcon  className={t.complete === 'pending' ? 'season_name__roadmap_pending pe-2' : 'pe-2'} size='sm' icon={faMinus} />
                                                    }
                                                    <span className={t.complete === 'pending' ? 'season_name__roadmap_pending' : ''} style={t.complete ? {color: 'white'} : {}} dangerouslySetInnerHTML={{ __html: t.name}}></span>
                                                </div>    
                                            )}
                                        </div>
                                    </Col>
                                )}
                                </Fragment>
                            )
                        }
                    </Row>
                </Row>
                }

                {matches &&        
                <>
                <Grid container spacing={0}>
                    <Grid container direction="column" item xs={5}></Grid>
                    <Grid container item xs={7}>
                        <div className='div_container_dashes__roadmap'>
                            <div className='div__dashes__roadmap'></div>
                        </div>
                        {
                            roadmaps.filter(x => {return x.id.toString() === selected.toString()}).map(x => 
                                <Grid item xs={12} key={x.id}>
                                {x.seasons && x.seasons.map(s => 
                                    <Col style={{position: 'relative'}} key={s.name}>  
                                        <h6 className={s.complete === 'pending' ? 'season_name__roadmap_pending season_name__roadmap' : 'season_name__roadmap'} style={s.complete === 'true' ? {} : {color: '#ffffff49'}}>{s.name}</h6> 
                                        <div className='circle-icon__roadmap' style={s.complete === 'true' ? {background: 'green'} : {}}>
                                            {s.complete === 'true' && 
                                                <FontAwesomeIcon size='xs' icon={faCheck} />
                                            }
                                            {s.complete === 'pending' && 
                                                <FontAwesomeIcon size='xs' className='fa-spin' icon={faSpinner} color='white' />
                                            }
                                        </div>
                                        <div className='tasks_container__roadmap'>
                                            {s.tasks && s.tasks.map(t => 
                                                <div className='tasks_text__roadmap' key={t.name}>
                                                    {t.complete === true &&
                                                        <FontAwesomeIcon style={{color: 'white'}} className='pe-2' size='sm' icon={faCheck} />
                                                    }
                                                    {(t.complete === false || t.complete === 'pending')  &&
                                                        <FontAwesomeIcon  className={t.complete === 'pending' ? 'season_name__roadmap_pending pe-2' : 'pe-2'} size='sm' icon={faMinus} />
                                                    }
                                                    <span className={t.complete === 'pending' ? 'season_name__roadmap_pending' : ''} style={t.complete ? {color: 'white'} : {}} dangerouslySetInnerHTML={{ __html: t.name}}></span>
                                                </div>    
                                            )}
                                        </div>
                                    </Col>
                                )}
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>   
                </>
                }
            </div>
        </section>
    )
}

export default Roadmap;