import { TextField, Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState, useEffect } from 'react';
import PageTitle from '../../helpers/PageTitle';
import Container from '@mui/material/Container';
import { useDispatch} from 'react-redux';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { setFirebaseCred } from '../../redux/reducers/adminFirebase';

export default function LogInFireBase({setSuccess}){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setErrors] = useState('');
    const [showPass, setShowPass] = useState(false);
    const auth = getAuth();

    const dispatch = useDispatch();

    useEffect(() => {
        setErrors('');
    }, [password, username])

    const logIn  = async () => {
        if(password !== ''  && username !== ''){
            setErrors('');
            try{
               signInWithEmailAndPassword(auth, username, password)
                .then((userCredential) => {
                    // Signed in 
                    const user = userCredential.user;
                    if(user){
                        dispatch(setFirebaseCred(user));
                    }
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    setErrors(errorMessage);
                });
            }catch(err){
                if(err?.response?.data?.message){
                    setErrors(err?.response?.data?.message)
                }
            }
        }else{
            setErrors('Fill in the form');
        }
    }

    return(
    <div className="questing_page" style={{display: 'flex', justifyContent: 'center'}}>
        <PageTitle title="Admin LogIn" />
        <Container maxWidth="md">
            <div className="form_questing">
                <h4>Log In News | Admin Panel</h4>
                <TextField 
                    id="input_username_settings" 
                    sx={{color: 'white'}} 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)} 
                    size="small" fullWidth label="Email" 
                    className="input_quest" type="email" 
                />
                    <TextField 
                    sx={{color: 'white'}} 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} 
                    size="small" fullWidth label="Password" 
                    className="input_quest" 
                    type={showPass ? "text" : "password"} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment onClick={() => setShowPass(!showPass)} style={{cursor: 'pointer'}} position="end">
                            {!showPass ? <VisibilityOffIcon/> : <VisibilityIcon />}
                            </InputAdornment>
                        ),
                        }}
                />
                <Button style={{width: '100%'}} size="small" className="btn_quest" onClick={() => logIn()} variant="contained">LOG IN</Button>
                <div className="error_form">
                    {error !== '' && <span>{error}</span>}
                </div>
            </div>
        </Container>
    </div>
    )
}