import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TextField } from '@mui/material';
import axiosInstance from '../redux/api';
import { useSelector, useDispatch} from 'react-redux';
import { setUser } from '../redux/reducers/userQuestingReducer';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function SetAccount({showAcc, setShowAcc, setSuccess}){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setErrors] = useState('');
    const [showPass, setShowPass] = useState(false);

    const matches = useMediaQuery('(max-width:600px)');

    const fullscreen = matches;

    const {user} = useSelector(state => state.userQuesting);

    const handleClose = () => setShowAcc(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        setErrors('');
    }, [password, username, confirmPassword])
    
    const setProfile  = async () => {
        if(password !== '' && confirmPassword !== '' && username !== ''){
            if(password === confirmPassword){
                setErrors('');
                try{
                    let str = '?password='+password+'&password_confirmation='+confirmPassword+'&username='+username;
            
                    const res = await axiosInstance.put('/users/'+user?.id+str);

                    if(res?.data?.data){
                        dispatch(setUser(res?.data?.data));
                        setSuccess('Game Account created');
                        setShowAcc(false);
                    }
                }catch(err){
                    if(err?.response?.data?.message){
                        setErrors(err?.response?.data?.message)
                    }
                }
            }else{
                setErrors('Passwords dont match');
            }
        }else{
            setErrors('Fill in the form');
        }
    }
  
    return (
        <Modal
          fullscreen={fullscreen}
          show={showAcc}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title style={{textAlign: 'center', width: '100%'}}>Set Game Account</Modal.Title>
          </Modal.Header>
          <Modal.Body className="body_setacc">
            <div className="form_questing ps-0 pe-0">
                <TextField 
                    id="input_username_settings" 
                    sx={{color: 'white'}} 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)} 
                    size="small" fullWidth label="Username" 
                    className="input_quest" type="text" 
                />
                 <TextField 
                    sx={{color: 'white'}} 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} 
                    size="small" fullWidth label="Password" 
                    className="input_quest" 
                    type={showPass ? "text" : "password"} 
                    InputProps={{
                        endAdornment: (
                          <InputAdornment onClick={() => setShowPass(!showPass)} style={{cursor: 'pointer'}} position="end">
                            {!showPass ? <VisibilityOffIcon/> : <VisibilityIcon />}
                          </InputAdornment>
                        ),
                      }}
                />
                <TextField 
                    sx={{color: 'white'}} 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)} 
                    size="small" fullWidth label="Confirm Password" 
                    className="input_quest"
                    type={showPass ? "text" : "password"} 
                />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{width: '100%'}} onClick={() => setProfile()}  variant="primary">Update</Button>
            <div className="error_form">
                {error !== '' && <span>{error}</span>}
            </div>
          </Modal.Footer>
        </Modal>
    );
}