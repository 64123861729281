import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Modal from 'react-bootstrap/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ConfirmDelete({showAcc, setShowAcc, setSuccess, selected, deleteElement}){
    const matches = useMediaQuery('(max-width:600px)');

    const fullscreen = matches;

    const handleClose = () => setShowAcc(false);
    
    return (
        <Modal
          fullscreen={fullscreen}
          show={showAcc}
          onHide={handleClose}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title style={{textAlign: 'center', width: '100%'}}>Delete Quest</Modal.Title>
          </Modal.Header>
          <Modal.Body className="body_setacc">
            <div className="form_questing ps-0 pe-0">
                Are you sure you want to delete quest {selected?.reward_name} with id {selected?.reward_id}?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup>
                <Button style={{width: '100%'}} onClick={() => deleteElement()} variant="danger">Delete</Button>
                <Button style={{width: '100%'}} onClick={handleClose} variant="primary">Close</Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
    );
}