import { TextField, Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState, useEffect } from 'react';
import PageTitle from '../../helpers/PageTitle';
import Container from '@mui/material/Container';
import axiosInstance from '../../redux/api';
import { useSelector, useDispatch} from 'react-redux';
import { setUser, setToken } from '../../redux/reducers/userQuestingReducer';

export default function LogInAdmin({setSuccess}){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setErrors] = useState('');
    const [showPass, setShowPass] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setErrors('');
    }, [password, username])

    const logIn  = async () => {
        if(password !== ''  && username !== ''){
            setErrors('');
            try{
                const form = new FormData();
                form.append('email', username);
                form.append('password', password)
                const res = await axiosInstance.post('auth/login', form);

                if(res?.data?.data?.token){
                    dispatch(setToken(res.data?.data?.token));

                    const result1 = await axiosInstance.get('auth/me');
                    if(result1.data?.data){
                        if(result1.data?.data?.id === 1){
                            dispatch(setUser(result1.data?.data))
                            setSuccess('Log in successfull');
                        }else{
                            setErrors('User is not an admin');
                            dispatch(setToken(null));
                        }
                    }
                }
            }catch(err){
                if(err?.response?.data?.message){
                    setErrors(err?.response?.data?.message)
                }
            }
        }else{
            setErrors('Fill in the form');
        }
    }

    return(
    <div className="questing_page" style={{display: 'flex', justifyContent: 'center'}}>
        <PageTitle title="Admin LogIn" />
        <Container maxWidth="md">
            <div className="form_questing">
                <h4>Log In | Admin Panel</h4>
                <TextField 
                    id="input_username_settings" 
                    sx={{color: 'white'}} 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)} 
                    size="small" fullWidth label="Email" 
                    className="input_quest" type="email" 
                />
                    <TextField 
                    sx={{color: 'white'}} 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} 
                    size="small" fullWidth label="Password" 
                    className="input_quest" 
                    type={showPass ? "text" : "password"} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment onClick={() => setShowPass(!showPass)} style={{cursor: 'pointer'}} position="end">
                            {!showPass ? <VisibilityOffIcon/> : <VisibilityIcon />}
                            </InputAdornment>
                        ),
                        }}
                />
                <Button style={{width: '100%'}} size="small" className="btn_quest" onClick={() => logIn()} variant="contained">LOG IN</Button>
                <div className="error_form">
                    {error !== '' && <span>{error}</span>}
                </div>
            </div>
        </Container>
    </div>
    )
}