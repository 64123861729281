import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import PageTitle from '../helpers/PageTitle';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WalletIcon from '@mui/icons-material/Wallet';

import AccountSettings from '../components/UserSettings/AccountSettings';
import '../assets/css/Settings.css';
import WalletSettings from '../components/UserSettings/WalletSettings';
import GameAccSettings from '../components/UserSettings/GameAccSettings';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

export default function UserSettingsPage({setSuccess, setError}){
    const [active, setActive] = useState('');
    const {path_settings} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if(!path_settings){
        navigate('/user/settings/account');
      }else{
        if(path_settings === 'account' || path_settings === 'wallet'|| path_settings === 'game-account'){
            setActive(path_settings.toLowerCase());
        }else{
            navigate('/user/settings/account');
        }
      }
    }, [path_settings, navigate])
    
    const goToLink = (link) => {
        navigate('/user/settings/'+link);
    }

    function capitalizeFirstLetter(string) {
        let strs = string.split(' ');
        let final = ''
        strs.forEach(s => {
            final+= ' ' + s.charAt(0).toUpperCase() + s.slice(1);
        })
        return final;
    }

    return(
        <div style={{paddingTop: '5rem'}} className="questing_page">
            <PageTitle title="User Settings" />
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <List className="list_settings" component="nav" aria-label="list_settings">
                            <ListItemButton onClick={() => goToLink('account')}>
                                <ListItemIcon>
                                    <ManageAccountsIcon />
                                </ListItemIcon>
                                <ListItemText className={active === 'account' ? 'text_settings_active' : ''} primary="Account" />
                            </ListItemButton>
                            <ListItemButton onClick={() => goToLink('wallet')}>
                                <ListItemIcon>
                                    <WalletIcon />
                                </ListItemIcon>
                                <ListItemText className={active === 'wallet' ? 'text_settings_active' : ''}  primary="Wallet" />
                            </ListItemButton>
                            <ListItemButton onClick={() => goToLink('game-account')}>
                                <ListItemIcon>
                                    <SportsEsportsIcon />
                                </ListItemIcon>
                                <ListItemText className={active === 'game-account' ? 'text_settings_active' : ''}  primary="Game Account" />
                            </ListItemButton>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {path_settings &&
                            <>
                            <div className="sign_in_par_quest ps-4 pe-4">
                                <h4 className="pt-3 pb-2">{capitalizeFirstLetter(String(path_settings).replaceAll('-', ' ')) + ' Settings'}</h4>
                                <Divider />
                                {path_settings === 'wallet' && 
                                    <WalletSettings setSuccess={setSuccess} setError={setError}/>
                                }

                                {path_settings === 'account' &&
                                    <AccountSettings setSuccess={setSuccess} setError={setError} />
                                }

                                {path_settings === 'game-account' &&
                                    <GameAccSettings setSuccess={setSuccess} setError={setError} />
                                }
                             </div>
                            </>
                        }
                        
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}