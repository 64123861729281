import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    accessToken: null,
    userId: null,
    account: null,
    wallet: null
};

const userIskraSlice = createSlice({
    name: "userIskra",
    initialState: initialState,
    reducers: {
        setIskraCred: (state, action) => {
            state.userId = action.payload.userId;
            state.accessToken = action.payload.accessToken;
        },
        setUserInfoIskra: (state, action) => {
            state.account = action.payload;
        },
        setUserWalletIskra: (state, action) => {
            state.wallet = action.payload;
        },
        logOutIskra: (state) => {
            state.userId = null;
            state.accessToken = null;
            state.account = null;
            state.wallet = null;
        }
    }
});

export const {setIskraCred, setUserInfoIskra, setUserWalletIskra, logOutIskra} = userIskraSlice.actions;

export default userIskraSlice.reducer;