import {useParams} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageTitle from '../helpers/PageTitle';

export default function RewardsQuesting(){
    const {reward_id, name} = useParams();
    const matches = useMediaQuery('(max-width:600px)');

    return(
        <div style={{display: 'flex', background: 'white', justifyContent: 'center', alignItems: 'center'}} className="questing_page">
            <PageTitle title="Rewards" />
            <div className="pt-5 pb-5">
                <iframe 
                frameborder="0" 
                allowtransparency="true" 
                className="widget-preview__frame" 
                style={{ minHeight: matches ? '430px' : '450px', height: '100%', minWidth: matches ? '330px' : '540px', margin: '0 auto'}} 
                src={`https://gleam.io/${reward_id}/embed`} 
                allow="storage-access *; attribution-reporting *; autoplay *; fullscreen *" 
                title={"Claim Reward for " + String(name).replaceAll('-', ' ')}></iframe>
            </div>
        </div>
    )
}