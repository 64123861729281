import '../assets/css/News.css';
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import test from '../assets/img/galactic-bg.jpg';

import { useEffect, useState } from 'react';
import { collection, query, orderBy, getDocs, where} from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import db from '../redux/firebase';

export default function News(){
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [mostViewedItem, setMostViewedItem] = useState(null);
    const [top, setTopItem] = useState(null);

    useEffect(() => {
        const fetchItems = async () => {
          try {
            // Extract data from all documents
            const q = query(collection(db.news, 'news'), where('isTop', '==', false), orderBy('created_at', 'desc'));
            const querySnapshot = await getDocs(q);
            const itemsData = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setItems(itemsData);

            // Fetch the item with the most views
            const qMostViewed = query(collection(db.news, 'news'), orderBy('views', 'desc'));
            const mostViewedSnapshot = await getDocs(qMostViewed);
            
            if (!mostViewedSnapshot.empty) {
                const mostViewedDoc = mostViewedSnapshot.docs[0];
                setMostViewedItem({
                    id: mostViewedDoc.id,
                    ...mostViewedDoc.data()
                });
            } 

            //Fetch the top news 
            const qTop = query(collection(db.news, 'news'), where('isTop', '==', true));
            const topSnapshot = await getDocs(qTop);

            if (!topSnapshot.empty) {
                const topDoc = topSnapshot.docs[0];
                setTopItem({
                    id: topDoc.id,
                    ...topDoc.data()
                });
            } 

          } catch (error) {
            console.error('Error fetching documents: ', error);
          }
        };
    
        fetchItems();
      }, []);

    const convertDate = (timestamp) => {
        try{
            // Convert Firestore Timestamp to JavaScript Date object
            const jsDate = timestamp.toDate();

            // Format the date as "Full month name Day, YEAR"
            const options = { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
            };
            const formattedDate = new Intl.DateTimeFormat('en-US', options).format(jsDate);
            return formattedDate;
        }catch{
            return timestamp?.seconds;
        }
    }
  
    return(
        <div className="news_section">
            <Container>
                {(items?.length > 0 || top) &&
                <div className="news_latest">
                    All Latest
                </div>
                }

                <Grid container spacing={2}>
                    {/* LATEST 4 */}
                    {items && items.slice(0, 4).map(item =>
                        <Grid item xs={6} sm={3} md={3}>
                            <div onClick={() => {navigate('/news/'+item.id)}} className="news_box">
                                <div className="shadowed_box"/>
                                <img className="news_box_img" src={item.img_small} alt="bg" />
                                <div className="text_news_box_z3">
                                    <div><AccessTimeIcon/> {convertDate(item.created_at)}</div>
                                    <div>{item.title}</div>
                                </div>
                            </div>
                        </Grid>
                    )}

                    {items && items.length === 1 &&
                        <Grid item xs={6} sm={3} md={3}></Grid>
                    }
                    
                    {/* TOP */}
                    <Grid item xs={12} sm={9}>  
                    {top &&
                        <div onClick={() => {navigate('/news/'+top.id)}}  className="news_box_long">
                            <div className="shadowed_box"/>
                            <img className="news_box_img" src={top.img_top} alt="bg" />

                            <div className="text_news_box_z3_long">
                                <div className="top_news">
                                    TOP NEWS
                                </div>
                                <div><AccessTimeIcon/> {convertDate(top.created_at)}</div>
                                <div>{top.title}</div>
                            </div>
                        </div>
                    }
                    </Grid>
                    
                    {/* MOST READ */}
                    <Grid item xs={12} sm={3}>
                    {mostViewedItem &&
                        <Grid onClick={() => {navigate('/news/'+mostViewedItem.id+'/true')}}  style={{height: '100%'}} className="shadow_other_box" container spacing={0}>
                            <Grid item xs={6} sm={12}>
                                <div className="news_box1">
                                    <img className="news_box_img" src={mostViewedItem.img_small} alt="bg" />
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={12}>
                                <div className="paper_box" style={{height: '100%', position: 'relative'}}>
                                    <div className="text_news_box_z3_short" style={{position: 'relative'}}>
                                        <div className="mr_news">
                                            MOST READ
                                        </div>
                                        <div><AccessTimeIcon/> {convertDate(mostViewedItem.created_at)}</div>
                                        <div style={{color: 'white'}}>{mostViewedItem.title}</div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    }
                    </Grid>

                    </Grid>

                    {/* OTHER PAGINATION */}
                    {items.length > 4 && 
                    <>
                    <div className="news_latest mt-5">
                        More News
                    </div>

                    <Grid container spacing={2}>
                    {items.slice(4).map(item =>
                        <Grid item xs={6} sm={3}>
                            <div onClick={() => {navigate('/news/'+item.id)}} className="shadow_other_box">
                                <div className="news_box1">
                                    <img className="news_box_img" src={item.img_small} alt="bg" />
                                </div>
                                <div className="paper_box">
                                    <div className="time_box_news"><AccessTimeIcon className="news_access_icon"/> {convertDate(item.created_at)}</div>
                                    <div className="title_box_news">{item.title}</div>
                                </div>
                            </div>
                        </Grid>
                    )}
                    </Grid>
                    </>}
            </Container>
        </div>
    )
}