import DonutChart from '../helpers/ThreeDonutChart';
import '../assets/css/Tokenomics.css';
import spark from '../assets/img/token-spark.png'
import { Col, Container, Row, Table } from 'react-bootstrap';
import PageTitle from '../helpers/PageTitle';

var data = [
    {
      country: "Advisors Fund",
      value: 10,
      radiusValue: 90,
      color: '#9c8cfc'
    },
    
    {
      country: "Public Sale",
      value: 5,
      radiusValue: 80,
      color: '#8c9cfc'
    },
    {
      country: "Private Round",
      value: 10,
      radiusValue: 90,
      color: '#8ccdfc'
    },
    {
      country: "Team Tokens",
      value: 15,
      radiusValue: 80,
      color: '#8cfce5'
    },
    {
      country: "Seed Round",
      value: 15,
      radiusValue: 90,
      color: '#4e8ab5'
    },
    {
      country: "Player Rewards",
      value: 15,
      radiusValue: 80,
      color: '#6233ff'
    },
    {
      country: "Liquidity",
      value: 15,
      radiusValue: 90,
      color: '#99e6e6'
    },
   
    {
      country: "Initial Staking Rewards Pool",
      value: 15,
      radiusValue: 80,
      color: '#66cccc'
    },
    
  ];

var tokenomics = [
    {id: 0, name: 'Seed Round', distribution: '15%', tge: '20%', locked: '3 Months', vesting: '6'},
    {id: 1, name: 'Private Round', distribution: '10%', tge: '20%', locked: '6 Months', vesting: '6'},
    {id: 2, name: 'Public Sale', distribution: '5%', tge: '---', locked: '---', vesting: '---'},
    {id: 3, name: 'Advisors', distribution: '10%', tge: '---', locked: '6 Months', vesting: '24'},
    {id: 4, name: 'Team', distribution: '15%', tge: '---', locked: '6 Months', vesting: '24'},
    {id: 5, name: 'Player Rewards', distribution: '15%', tge: '---', locked: '6 Months', vesting: '24'},
    {id: 6, name: 'Liquidity', distribution: '15%', tge: '---', locked: '---', vesting: '---'},
    {id: 7, name: 'Initial Staking Rewards Pool', distribution: '15%', tge: '---', locked: '---', vesting: '---'},
]

const utility = [
    {id: 0, name: 'Live Auction Bid', img: '/static/icons/auction.png', desc: "Essential items are exclusively supplied through auction, enabling players to bid based on market demand and supply, rather than a fixed price. 30% of losing bids directly support the game treasury, ensuring a steady flow of resources for ecosystem growth."},
    {id: 1, name: 'Forge Guardians', img: '/static/icons/forge.png', desc: "Forge guardians with desired towers, though outcomes are unpredictable. Each guardian creation offers a chance for unique combinations, adding excitement to the process."},
    //{id: 2, name: 'Upgrade Throne MAX HP', img: '/static/icons/throne.png', desc: "Climbing the single-player leaderboard in 'Guardians Of The Spark' involves strategic token use. Level up items and boost SPARK (THRONE) HP for a survival advantage, enhancing play-to-earn mechanics and showcasing dominance on the leaderboard."},
    //{id: 3, name: 'Sustainable Staking', img: '/static/icons/staking.png', desc: "Staking enhances the $SPARK token by fostering network security, incentivizing participation, and promoting a stable ecosystem. Additionally, owning rare digital assets boosts your staking by providing additional incentives and rewards for $SPARK token holders."},
    //{id: 4, name: 'Cosmetics', img: '/static/icons/cosmetics.png', desc: "Cosmetics in the game not only enhance the visual experience but also serve as a strategic utility for $SPARK token holders. By utilizing tokens for exclusive cosmetic items, players not only personalize their in-game appearance but also contribute to the token's ecosystem, promoting its value and sustainability."},
    {id: 5, name: 'Buy back', img: '/static/icons/buyback.png', desc: "If your SPARK FALLS IN BATTLE, you face a temporary hiatus of 24 hours before you can jump back into the action. To rejoin, pay tokens, available once per day or win a live auction. Alternatively, for a quick in-game revival you can select watching a few daily ads—a time exchange option."},
    //{id: 6, name: 'Remove Ads', img: '/static/icons/adds.png', desc: "Even the ads hold value here by offering lives and extra gold. If ads aren't your preference, a turn-off button is available in a form of a monthly subscription."},
    //{id: 7, name: 'Guardians Body Parts Upgrade', img: '/static/icons/upgrade.png', desc: "Customize your defense using NFT Guardians with 5 upgradeable body parts and power-up slots that can be enhanced with Spark Tokens. This unique power-up system, featuring Common to Mythical items with 100 upgrade levels"},
]
function Tokenomics(){
    return(
        <section className='tokenomicsSection pb-5'>
            <PageTitle title="Tokenomics" />
            <Container className='header_container__tokenomics mt-5' style={{alignItems: 'start', justifyContent: 'start', display: 'flex', flexDirection: 'column'}}>
                {/*  <h6 className="subtitle__gameplay" style={{paddingTop: '0', paddingBottom: '0', textAlign: 'start'}}>GOTS TOKENOMICS</h6> */}
                <h1 className='h1_title__tokenomics' style={{textAlign: 'center', padding: '0'}}>ALLOCATION</h1>
            </Container>
            <Container className='mt-5 pt-3'>
                <Row className='row_tokenomics__mobile'>
                    <Col className='col_donut__tokenomics'>
                        <DonutChart data={data} />
                    </Col>
                    <Col className='col-desc__tokenomics pt-2'>
                        <h6 className='h6__tokenomics'>Total Supply: 150,000,000</h6>
                        {/* 
                        <h6 className='h6__tokenomics'>Public Sale: $0.053</h6>*/}
                        <Row className='row_tokenomics__dist' style={{height: '80%'}}>
                            {data.map(d => 
                                <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                    <div className='flex__tokenomics_inner'>
                                        <div className='square__tokenomics' style={{background: d.color}}></div>
                                        <div className='ps-3'>{d.country}</div>
                                    </div>
                                    <div className='perc__tokenomics'>{d.value}%</div>
                                </Col>    
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>

            {/*
            <Container className='pt-5 mt-5 container_table__tokenomics'>
                <h1 className='h1_title__tokenomics' style={{padding: '0', paddingTop: '4rem', paddingBottom: '1rem'}}>TOKENOMICS</h1>
                <Table style={{overflowX: 'scroll'}} striped bordered variant="dark">
                    <thead>
                        <tr>
                        <th>CATEGORY</th>
                        <th>ALLOCATION</th>
                        <th>TGE</th>
                        <th style={{textAlign: 'end'}}>LOCKED</th>
                        <th className='last_child' style={{textAlign: 'end'}}>VESTING(MONTHS)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tokenomics.map(t => 
                             <tr key={t.id}>
                                <td>{t.name}</td>
                                <td>{t.distribution}</td>
                                <td>{t.tge}</td>
                                <td style={{textAlign: 'end'}}>{t.locked}</td>
                                <td className='last_child' style={{textAlign: 'end'}}>{t.vesting}</td>
                             </tr>
                        )}
                    </tbody>
                    </Table>
            </Container>
            */}
            

            <Container style={{position: 'relative'}} className='pt-5 mt-5  pb-5'>
                <img src={spark} alt='spark' className='spark__tokenomics_img' />
                <img src={spark} alt='spark' className='spark1__tokenomics_img' />
                <div style={{position: 'relative', zIndex: '5'}}>
                    <h1 className='h1_title__tokenomics' style={{padding: '0', paddingTop: '4rem', paddingBottom: '1rem'}}>UTILITY</h1>
                    <h6 className='h6__tokenomics' style={{color: '#fff3b5'}}>$SPARK TOKEN</h6>
                    <p className='p__tokenomics'>$SPARK serves as the indigenous currency and governance tool within the Guardians of the Spark ecosystem. Players have the opportunity to acquire it through in-game rewards and staking. Our aim is to empower players by allowing them to take control, addressing the common issues that arise when a central authority monopolizes decision-making. Here are several functionalities that enhance the strength of the token.</p>
                
                    <div className='pt-5 row_utility__tokenomics'>
                        {utility.map(u => 
                            <div key={u.id} className='col_utility__tokenomics'>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '1rem'}}>
                                    <h6 className='h6_col__tokenomics'>{u.name}</h6>

                                    <img className='img_utility__tokenomics' src={u.img} alt='icon' />
                                </div>
                                <p style={{textAlign: 'center', width: '100%'}} className='p__tokenomics'>
                                    {u.desc}
                                </p>
                            </div>
                        )}
                      

                    </div>
                </div>
            </Container>
            
        </section>
    )
}
export default Tokenomics;