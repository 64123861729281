import { Col, Container, Row } from "react-bootstrap";
import logo2 from '../../assets/img/logo-2.png';
import { useEffect, useState } from "react";

const typesFCT =[
    {id: 0, name: 'Endless Gameplay', title: 'Endless Gameplay', img: '/static/heroes/elf.png'},
    {id: 1, name: 'Auction System', title: 'Auction System', img: '/static/heroes/dark.png'},
    {id: 2, name: 'Guild System', title: 'Guild System', img: '/static/heroes/kotl.png'},
    {id: 3, name: 'Forging Guardians', title: 'Forging Guardians', img: '/static/heroes/skeleton.png'}
]
function GamePlay() {
    const [selectedFCT, changeSelectedFCT] = useState(0);
    const [containerOffset, setContainerOffset] = useState(20);

    const handleScroll = (event) => {
        event.preventDefault()
        const container = document.getElementById('col-container-gameplay');
        const maxOffset = container.scrollHeight - container.clientHeight;
        const newOffset = Math.min(maxOffset, Math.max(0, container.scrollTop));

        setContainerOffset(newOffset);
    };

    useEffect(() => {
        const container = document.getElementById('col-container-gameplay');
        if(container){
            container.addEventListener('scroll', handleScroll);
        }
        return () => {
            if(container){
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    function updateSelectedFCT(id){
        if(id !== selectedFCT){
            let component = document.getElementById('id-img-gameplay')
            const container = document.getElementById('col-container-gameplay');
            if(component){
                component.classList.add('show-image_animation__gameplay');

                setTimeout(() => {
                    component.classList.remove('show-image_animation__gameplay');
                }, 500);
            }

            if(container){
                container.scrollTop = 0;
            }
           
            changeSelectedFCT(id);
        }
    }

    return(
        <section className="gameplay-section">
            <h6 className="subtitle__gameplay">GOTS Features</h6>

            <div className="gameplay__box_container">
                <div className="gameplay__box">
                    <Row className="row_gameplay__box" style={{width: '100%', height: '100%', position: 'relative', boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.15)'}}>
                        <Col id='col-container-gameplay' className="desc_col__gameplay" style={{ position: 'relative' }}>
                            <div id='id-img-gameplay' style={{top: `${containerOffset}px`}} className="sticky_img">
                                <img className="sticky_img__gameplay" src={process.env.PUBLIC_URL + typesFCT[selectedFCT].img} alt='tower-img' />
                            </div>

                            <div className="title_gameplay_desc pb-4">{typesFCT[selectedFCT].title}</div>
                        
                            <div className="pt-4  text-desc__gameplay">
                                {/* FORGE */}
                                {selectedFCT === 0 &&
                                    <>
                                        <div>
                                        Replace primitive energy with one-life-per-day method, allowing continuous play and earning of
                                        $SPARK until defeat. "Play Again"
                                        costs $SPARK tokens, encouraging
                                        investment for character growth.
                                        </div>
                                        {/* 
                                        <h6 className="h6__gameplay_subs pt-5">Guardian’s types</h6>
                                        <p>
                                            Discover and select from six unique hero types, each equipped with their own set of strengths and abilities. Tailor your gameplay to your liking by choosing one of many distinct characters.
                                        </p>
                                        
                                        <div className="other-btns">Learn more</div>

                                        <h6 className="h6__gameplay_subs pt-5">Body Parts as Towers</h6>
                                        <p>
                                            Your hero is made up of various body parts, with each part symbolizing a tower in the game.
                                        </p>
                                        <div className="ul__gameplay">
                                            <ul>
                                                <li>Guardian’s Head ↬ Tower A</li>
                                                <li>Guardian’s Body ↬ Tower B</li>
                                                <li>Guardian’s Arms ↬ Tower C</li>
                                                <li>Guardian’s Legs ↬ Tower D </li>
                                                <li>Guardian’s Weapon ↬ Tower E</li>
                                                <li>Guardian’s  Shield ↬ Tower F</li>
                                            </ul>
                                        </div>

                                        <h6 className="h6__gameplay_subs pt-5">Forging Process</h6>
                                        <p>
                                            Forge your Guardian by choosing one option from each body part category. Combine and match to assemble a fully equipped Guardian prepared for battle.
                                        </p>

                                        <h6 className="h6__gameplay_subs pt-5">Tower Abilities</h6>
                                        <p>
                                            Each tower contributes unique abilities. The head, arms, weapon, and other components of your Guardian play a vital role in shaping your strategy and influencing your in-game strengths.
                                        </p>

                                        <h6 className="h6__gameplay_subs pt-5">Gameplay Impact</h6>
                                        <p>
                                            The combination of towers directly impacts your gameplay. Experiment with different Guardian's to discover the best tactics for various challenges.
                                        </p>

                                        <h6 className="h6__gameplay_subs pt-5">Variety and Customization</h6>
                                        <p>
                                            Indulge in the freedom of customizing your Guardian. With countless combinations available, discover the perfect balance that aligns with your preferred playstyle or adapts to various situations.
                                        </p>

                                        <h6 className="h6__gameplay_subs pt-5">Progression</h6>
                                        <p className="pb-5">
                                        As you progress, forging more powerful Guardian's becomes key to overcoming tougher foes and advancing through the game's levels. Adapt and evolve your heroes as you face new challenges.
                                        </p>*/}
                                    </>
                                }
                                {selectedFCT === 1 &&
                                    <>
                                    <div>Essential items are exclusively
                                        supplied through auction,
                                        enabling players to bid based on
                                        market demand and supply,
                                        rather than a fixed price.</div>
                                    {/*
                                    <h6 className="h6__gameplay_subs pt-5 pb-2">Craft, Collect, Command!</h6>
                                    <p>Immerse yourself in the core of our NFT game with Tower Items – the secret to your guardian's strength:</p> 
                                    <div className="pb-5">
                                        <ul>
                                            <li>Engage in monster hunts, auctions, and marketplace deals to collect unique items.</li><br/>
                                            <li>Strategically apply them to each tower for a personalized, potent guardian.</li><br/>
                                            <li>Choose wisely: Strengthen your guardian or trade for in-game riches. Each item is a unique NFT, enhancing your journey's value.</li><br/>
                                            <li>Craft your legacy, collect strategically, and lead the game with your unique guardians!</li><br/>
                                        </ul>
                                    </div>*/}
                                    </>
                                }
                                {selectedFCT === 2 &&
                                    <>
                                    <div>
                                    Guilds serve as social hubs,
                                    uniting members to complete
                                    quests or tasks for token rewards
                                    or exclusive items. GOTS supports
                                    a maximum of 400 guilds.
                                    </div>
                                    {/*
                                    <h6 className="h6__gameplay_subs pt-5">Trade, Prosper, Dominate!</h6>
                                    <div className="pb-5 pt-4">
                                        <ul>
                                            <li>
                                                <b>Dynamic Marketplace</b><br/>
                                                Engage in a player-driven marketplace where items are bought, sold, and traded. It's a dynamic ecosystem, reflecting the ever-shifting strategies of the player community.
                                            </li><br/>
                                            <li><b>Strategic Trading</b><br/>
                                                Master the art of strategic trading. Whether you're seeking specific items to boost your guardian or aiming to build in-game wealth, the marketplace is your arena.
                                            </li><br/>
                                            <li><b>Player Collaboration</b><br/>
                                                Connect with fellow players, negotiate trades, and form alliances. The marketplace isn't just about transactions; it's a hub for community interaction and collaboration.
                                            </li><br/>
                                            <li><b>Economic Gameplay</b><br/>
                                                Your choices in the marketplace impact the in-game economy. Trade wisely to build your wealth, fund your guardian's upgrades, or secure rare items to dominate the competition.
                                            </li><br/>
                                            <li><b>NFT Rarity</b><br/>
                                                Recognize the unique value of each traded item as an NFT. Every trade contributes to the rarity and uniqueness of your collection, solidifying your digital legacy.
                                            </li><br/>
                                            <li><b>Auction Excitement</b><br/>
                                                Participate in auctions for exclusive items. Outbid rivals and secure top-tier assets to gain a strategic advantage on your journey to dominance.
                                            </li><br/>
                                        </ul>
                                    </div>*/}
                                    </>
                                }
                                
                                {selectedFCT === 3 &&
                                <>
                                    <div>Forge guardians with desired towers,
                                    though outcomes are unpredictable.
                                    Each guardian creation offers a
                                    chance for unique combinations,
                                    adding excitement to the process.</div>
                                </>
                                }
                            </div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={3} xl={3} xxl={3} className="menu_col__gameplay">
                            {typesFCT && typesFCT.map(t => 
                                <div onClick={() => {updateSelectedFCT(t.id)}} className={selectedFCT === t.id ? 'boxMenu__gameplay boxMenu__selected' : 'boxMenu__gameplay'} key={t.id}>
                                    {t.name}
                                </div>    
                            )}
                        </Col>
                        <div className="floating_planet">
                            <img className="planet__gameplay" src={logo2} alt='floating-planet'/>
                        </div>
                    </Row>
                </div>
            </div>
        </section>
    )
}

export default GamePlay;