import {useEffect, useState, useCallback} from 'react';
import axiosInstance from '../../redux/api';
import FacebookCircularProgress from '../../helpers/FacebookCircularProgress';

export default function TransactionHistory() {
    const [transactions, setTransactions] = useState(null);
    const [loading, setLoading] = useState(true);

    const getTransactions = useCallback(
      async () => {
        if(transactions === null){
            try{
                let res1 = await axiosInstance.get('me/rewards');
                if(res1?.data?.data){
                    let res = await axiosInstance.get('rewards?page=1&per_page=10');
                    if(res?.data?.data){
                        let temp = res?.data?.data.filter(x => {
                            return res1.data.data.filter(y => {
                                return y.reward_id === x.reward_id
                            }).length > 0
                        })
                        setTransactions(temp)
                    }
                }
                setLoading(false);
            }catch{
                setLoading(false);
            }
        }
      },
      [transactions],
    )
    
    useEffect(() => {
      getTransactions();
    }, [getTransactions])
    
    return(
        <div className="quest_cont">
            {loading &&
                <div className="display_center">
                    <FacebookCircularProgress />
                </div>
            }
            {!loading && transactions?.length > 0 && transactions.map(q =>
                <div className="quest_link_row" key={q.id}>
                    <div className="quest_name">{q.reward_name}</div>
                    <div className="display_flex quest_name">
                       +{q.credits_amount} SP claimed
                    </div>
                </div>
            )}
            {!loading && transactions?.length === 0 &&
                <div className="no_t_quests">No transactions at the moment</div>
            }
        </div>
    )
}