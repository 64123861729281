import { useState } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { Button, TextField } from '@mui/material';
import { setUser, setToken} from '../../redux/reducers/userQuestingReducer';
import axiosInstance from '../../redux/api';
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function AccountSettings({setSuccess, setError}){
    const {account} = useSelector(state => state.userIskra);
    const {user} = useSelector(state => state.userQuesting);

    const [editEmail, setEditEmail] = useState(false);
    const [email, setEmail] = useState(user?.email ? user.email : '');
    const [username, setUsername] = useState(user?.username ? user.username : '');

    const [profile_img, setProfileImg] = useState(user?.profile_picture ? user.profile_picture : '')
    const [file, setFile] = useState(null);
    
    const dispatch = useDispatch();

    const validateEmail = (email1) => {
        return String(email1)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    const saveChanges = async () => {
        if(email !== '' && username !== ''){
            if(validateEmail(email)){
                try{
                    let str = '?email='+email+'&username='+username;
                    if(file){
                        let formData = new FormData();
                        formData.append('file', file);
                        await axiosInstance.post('set-profile-image', formData);
                    }
                    const res = await axiosInstance.put('/users/'+user?.id+str);
                    if(res?.data?.data){
                        setSuccess('Account updated successfully');
                        dispatch(setUser(res?.data?.data));
                        setEditEmail(false);
                        setProfileImg(res?.data?.data?.profile_picture);

                        let el = document.getElementById('input_email_settings');
                        let el1 = document.getElementById('input_username_settings');
                        if(el && el1){
                            el.blur();
                            el1.blur();
                        }
                    }
                }catch(err){
                    if(err.response?.data?.message){
                        setError(err.response.data.message)
                    }
                }
            }else{
                setError('Invalid email format');
            }
        }else{
            setError('Form fields are empty');
        }
    }

    const syncAccount = async () => {
        try{
            let fd = new FormData();
            fd.append('user_id', account?.userId);
            fd.append('access_token', account?.accessToken);
            const res = await axiosInstance.post(process.env.REACT_APP_VERIFY_URL, fd)

            if(res?.data?.data?.token){
            dispatch(setToken(res.data?.data?.token))

            const result1 = await axiosInstance.get('auth/me');
            if(result1.data?.data){
                dispatch(setUser(result1.data?.data))
                setProfileImg(result1.data?.data?.profile_picture);
                setUsername(result1.data?.data?.username ? result1.data?.data?.username : '');
                setEmail(result1.data?.data?.email ? result1.data?.data?.email : '')
                setSuccess('Account synced');
            }
            }
        }catch{
            setError("Account didn't sync. Try again later")
        }
    }

    const makeInputEmail = () => {
        setEditEmail(true);
        let el = document.getElementById('input_username_settings');
        if(el){
            el.focus();
        }
    }

    const closeForm = () => {
        setEditEmail(false);
        setProfileImg(user?.profile_picture);
        setEmail(user?.email);
        setUsername(user?.username);
    }

    const copyContent = (text) => {
        try{
            navigator.clipboard.writeText(text);
            setSuccess('User ID copied to clipboard')
        }catch{}
    }

    const updateProfileImage = () => {
        if(!editEmail){
            setEditEmail(true);
        }

        let el = document.getElementById('settings_input_pp');
        if(el){
            el.click();
        }
    }

    const handleFileSelected = (e): void => {
        const files = Array.from(e.target.files)

        if(files[0]){
            setFile(files[0]);
            previewFile(files[0]);
        }
    }

    function previewFile(file) {
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            setProfileImg(reader.result);
        }, false);
      
        if (file) {
          reader.readAsDataURL(file);
        }
      }


    return(
        <>
            {user.id === -1 &&
                <div className="settings_big_c">
                    <Button onClick={() => syncAccount()} variant="contained">Sync account</Button>
                </div>
            }
            {user.id !== -1 &&
                <div className="form_questing ps-0 pe-0">
                    <div className="display_center">
                        <div>
                            <div onClick={() => updateProfileImage()} className="profile_btn_img">
                                <input id="settings_input_pp" onChange={handleFileSelected} style={{display: 'none'}} type="file" />
                                <img style={{width : '100%'}} src={profile_img} alt="profile" />
                            </div>
                            <div className="profile_label_acc">Profile Picture</div>
                        </div>
                    </div>
                    <TextField 
                        InputProps={{ readOnly: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ContentCopyIcon onClick={() => copyContent(user?.user_id)} className="icon_input" />
                                </InputAdornment>
                            ),
                        }} 
                        sx={{color: 'white'}} 
                        value={user?.user_id} 
                        size="small" fullWidth label="User ID" 
                        className="input_quest" type="text" 
                    />

                    <TextField 
                        id="input_username_settings"
                        InputProps={{ readOnly: !editEmail}} 
                        sx={{color: 'white'}} 
                        value={username}
                        onChange={(e) => setUsername(e.target.value)} 
                        size="small" fullWidth label="Username" 
                        className="input_quest" type="text" 
                    />

                    <div>
                        <TextField 
                            id="input_email_settings"
                            InputProps={{ readOnly: !editEmail}} 
                            sx={{color: 'white'}} 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} 
                            size="small" fullWidth label="Email" 
                            className="input_quest" type="text" 
                        />

                        {!editEmail &&
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button onClick={() => makeInputEmail()} size="sm" variant="contained" className="mt-2 mb-2">
                                    Update profile info
                                </Button>
                            </div>
                        }

                        {editEmail &&
                            <div style={{display: 'flex', justifyContent: 'flex-end', gap: '1rem'}}>
                                <Button onClick={() => saveChanges()} size="sm" variant="contained" className="mt-2 mb-2">
                                    Save Changes
                                </Button>
                                <Button color="inherit" onClick={() => closeForm()} size="sm" variant="contained" className="mt-2 mb-2">
                                    Close
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}