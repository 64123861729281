import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../assets/css/Questing.css";
import SignInQuesting from '../components/Questing/SignInQuesting';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import Slide from '@mui/material/Slide';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { setCredits } from '../redux/reducers/userQuestingReducer';
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Quests from '../components/Questing/Quests';
import TransactionHistory from '../components/Questing/TransactionHistory';
import SetEmail from '../components/SetEmail';
import PageTitle from '../helpers/PageTitle';

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export default function QuestingPage(){
    const navigate = useNavigate();
    const {user} = useSelector(state => state.userQuesting);
    const {wallet} = useSelector(state => state.userIskra);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [transactionHash, setTransactionHash] = useState('');

    const [alignment, setAlignment] = useState('quests');
    const [showAddEmail, setShowAddEmail] = useState(false);

    useEffect(() => {
        if(user?.id !== -1){
            if(!user.email){
                setShowAddEmail(true);
            }
        }
    }, [user])
    
    const handleAlignment = (
      event,
      newAlignment
    ) => {
      setAlignment(newAlignment);
    };
  
    const dispatch = useDispatch();

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSuccess('');
    };

    const handleCloseError = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setError('');
    };

    const copyContent = (text) => {
        try{
            navigator.clipboard.writeText(text);
            setSuccess('Address copied to clipboard')
        }catch{}
    }

    function toTimestamp(strDate){
        var datum = Date.parse(strDate);
        return datum;
     }

    const convertDate = (d) => {
        const parts = String(d).split('T')
        if(parts[0] && parts[1]){
            let date = parts[0].split('-')
            const newD = date[1]+'/'+date[2]+'/'+date[0];
            let time = parts[1].split('.')
            const newT = time[0];
            return newD + ' ' + newT;
        }else{
            return d;
        }
    }
     
    const exchangeCredits = () => {
            setTransactionHash('');
            var lastDate = new Date(toTimestamp(String(convertDate(user?.last_exchanged)))).addDays(15).getTime();
            var now = new Date(Date.now()).getTime();

            if(user?.credits <= 0){
                setError("No credits for claim. Earn by completing quests")
            }
            else if(lastDate-now > 0){
                setError("Exchange is done every 15 days. Complete more quest and come back later for the claimed!")
            }else{
                dispatch(setCredits(0))
                setSuccess("Credits claimed successfully. Check your wallet or the transaction hash.")
                setTransactionHash('0xtestesttest')
            }
    }

    return(
        <div className="questing_page">
            <PageTitle title="Questing" />
            <SetEmail setSuccess={setSuccess} setShowAddEmail={setShowAddEmail} showAddEmail={showAddEmail} />
            <Snackbar
            open={success !== ''}
            TransitionComponent={SlideTransition}
            autoHideDuration={5000}
            onClose={handleClose}
            message={
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            {success}
            </Alert>}
            />

            <Snackbar
            open={error !== ''}
            TransitionComponent={SlideTransition}
            autoHideDuration={5000}
            onClose={handleCloseError}
            message={
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="error">
            {error}
            </Alert>}
            />

            {user.id === -1 &&
                <SignInQuesting setSuccess={setSuccess} setError={setError}/>
            }

            {user.id !== -1 &&
                <div className="sign_ques_cont">
                    <Container maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4}>
                                <div className="sign_in_par_quest">
                                    <h6 style={{textAlign: 'left'}} className="h6_questing pt-3">Welcome, {user?.username ? user.username: 'user'} 👋</h6>
                                    <div className="pt-3 pb-3 form_questing ps-0 pe-0" style={{gap: '2rem'}}>
                                        <TextField 
                                            InputProps={{ readOnly: true}} 
                                            sx={{color: 'white'}} 
                                            value={user?.email ? user?.email : ''} 
                                            size="small" fullWidth label="Email" 
                                            className="input_quest" type="text" 
                                            helperText={<span>Not the correct email? <span onClick={() => navigate('/user/settings/account')} className="link_text">Change it here.</span></span>}
                                            />
                                            
                                        <div>
                                            <TextField 
                                            id="address_change_quest"
                                            value={wallet?.walletAddress}
                                            InputProps={{ readOnly: true,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                    <ContentCopyIcon onClick={() => copyContent(wallet?.walletAddress)} className="icon_input" />
                                                    </InputAdornment>
                                                ),
                                            }} 

                                            sx={{color: 'white'}} 
                                            size="small" fullWidth label="Wallet Address" 
                                            className="input_quest" type="text" 
                                            />
                                        </div>
                                       
                                            <TextField 
                                                InputProps={{ readOnly: true}} 
                                                sx={{color: 'white'}} 
                                                value={user?.credits} 
                                                size="small" fullWidth label="SparkPoints" 
                                                className="input_quest" type="text" 
                                            />
                                            {/** <div>
                                                <TextField 
                                                    InputProps={{ readOnly: true}} 
                                                    sx={{color: 'white'}} 
                                                    value={convertDate(user?.last_exchanged)} 
                                                    size="small" fullWidth label="Last claimed" 
                                                    className="input_quest" type="text" 
                                                />
                                                
                                                <Button style={{width: '100%'}} size="small" className="btn_quest" onClick={() => {exchangeCredits()}} variant="contained">Claim Airdrop</Button>
                                                {transactionHash !== '' && <p className="p_desc"><ContentCopyIcon onClick={() => copyContent(transactionHash)} className="icon_input" /> Transaction: {transactionHash}</p>}
                                                
                                            </div>*/}
                                        <div>
                                        
                                        </div>
                                        
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                                <div className="sign_in_par_quest ps-4 pe-4">
                                    <div>
                                        <ToggleButtonGroup
                                        value={alignment}
                                        exclusive
                                        onChange={handleAlignment}
                                        aria-label="toggle questing"
                                        size="small"
                                        >
                                            <ToggleButton value="quests" aria-label="quests">
                                                Quests
                                            </ToggleButton>
                                            <ToggleButton value="transactions" aria-label="transactions">
                                                Transaction History
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>

                                    {alignment === "quests" && <Quests user={user} setError={setError} setSuccess={setSuccess} />}
                                    {alignment === "transactions" && <TransactionHistory/>}
                                </div>
                            </Grid>
                        </Grid>
                        
                    </Container>
                </div>
            }
        </div>
    )
}