import { Button, Col, Row } from "react-bootstrap";
import tokenImg from '../../assets/img/test-token.png'
import { useNavigate } from "react-router-dom";

const utilities = [
    {id: 1, text: 'Forge New Guardians', color: 'rgb(35 64 147)', top: 0, left: '9rem'},
    {id: 2, text: 'In-game resources', color: 'rgb(79 154 160)', top: '8rem', left: '18rem'},
    {id: 4, text: 'Body parts upgrade', color: 'rgb(67 115 150)', top: '15rem', left: '24rem'},
    {id: 5, text: 'Staking', color: 'rgb(25 20 72)', top: '15rem', left: '10rem'},
    {id: 7, text: 'Auctions', color: 'rgb(141 128 228)', top: 0, left: '27rem'},
]
function TokenDetails(){
    const navigate = useNavigate();
    return(
        <section className="tokendetails__section">
           
            <div className="box__tokendetails">

                <div className="blur__tokendetails" />
                <img className='img1__tokendetails' src={tokenImg} alt="token" />
                <img className='img2__tokendetails' src={tokenImg} alt="token" />

                <Row className="row__tokendetails">
                    <Col style={{display: 'flex',flexDirection: 'column', justifyContent: 'space-between'}}>
                        <div>
                            <h6 className="subtitle__gameplay" style={{paddingTop: '0', textAlign: 'start', color: '#234093'}}>TOKENOMICS</h6>
                            <h1 className="h1__tokendetails">$SPARK</h1>
                            <p className="p__tokendetails">Embark on an adventure in the lively realm of the Guardians of the Spark ecosystem, where $SPARK takes center stage! Earn this dynamic currency through exciting in-game rewards and engaging in staking activities. But that's not all – $SPARK with its numerous utilities, it is your ticket to a variety of exciting opportunities within the ecosystem.</p>
                        </div>
                        <div className="but__tokendetails">
                            <Button onClick={() => {navigate('/tokenomics')}} className="button__tokendetails">
                                <div className="inner_b__tokendetails"></div>
                                Learn More
                            </Button>
                        </div>
                    </Col>
                    <Col className="col_utilities__tokendetails">
                        <div className="pulse__tokendetails"></div>
                        <div className="col_utilities__tokendetails1">
                            {utilities.map(u => 
                                <div key={u.id} 
                                style={{
                                    background: 'linear-gradient(0deg, '+ u.color +', '+ u.color + '), '+u.color,
                                    boxShadow: '0px 0px 8px '+u.color,
                                    top: u.top,
                                    left: u.left
                                }}>
                                    {u.text}
                                </div>
                            )}
                        </div>
                       
                    </Col>
                </Row>
               
            </div>
        </section>
    )
}

export default TokenDetails;