import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import userQuestingReducer from "./reducers/userQuestingReducer";
import userIskraReducer from "./reducers/userIskraReducer";
import adminFirebase from "./reducers/adminFirebase";

const persistConfig = {
    key: "root",
    version: 1,
    storage
}

const reducer = combineReducers({
    userQuesting: userQuestingReducer,
    userIskra: userIskraReducer,
    userFirebase: adminFirebase
})

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});
