import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { doc, getDoc, collection, query, orderBy, getDocs, where, updateDoc } from 'firebase/firestore';
import db from '../redux/firebase';

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import test from '../assets/img/galactic-bg.jpg';
import getBrowserFingerprint from 'get-browser-fingerprint';

export default function NewsDescription(){
    const {id,isMostRead} = useParams();
    const navigate = useNavigate();

    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [otherItems, setItems] = useState([]);

    
    useEffect(() => {
        const fetchItem = async () => {
            try {
                const docRef = doc(db.news, 'news', id); // Replace 'yourCollectionName' with your actual collection name
                const docSnap = await getDoc(docRef);
        
                if (docSnap.exists()) {
                    setItem({ id: docSnap.id, ...docSnap.data() });
                } else {
                    navigate('/news')
                }

                const fingerprint = await getBrowserFingerprint();
                
                if(!docSnap.data()?.viewers.includes(fingerprint)){
                    const newViews = docSnap.data()?.viewers;
                    newViews.push(fingerprint);
                    await updateDoc(docRef, {
                        viewers: newViews,
                        views: (parseInt(docSnap.data()?.views) + 1)
                    });
                }

                // Extract data from all documents
                const q = query(collection(db.news, 'news'), where('id', '!==', id), orderBy('created_at', 'desc'));
                const querySnapshot = await getDocs(q);
                const itemsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
                }));
                setItems(itemsData);
                
            } catch (error) {
                console.error('Error fetching document:', error);
            } finally {
                setLoading(false);
            }
        };
      if(id){
        fetchItem();
      }
    }, [id, navigate])
    
    const convertDate = (timestamp) => {
        try{
            // Convert Firestore Timestamp to JavaScript Date object
            const jsDate = timestamp.toDate();

            // Format the date as "Full month name Day, YEAR"
            const options = { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
            };
            const formattedDate = new Intl.DateTimeFormat('en-US', options).format(jsDate);
            return formattedDate;
        }catch{
            return timestamp?.seconds;
        }
    }

    return(
        <div className="news_section pt-0">
            {!loading && item &&
                <>
                    {/* HEADER */}
                    <div className="news_det_header">
                        <div className="shadowed_box"/>
                            <img className="news_box_img" src={item.img_header} alt="header" />
                        <div className="text_news_det_header">
                            <Container>
                                <h3>{item.title}</h3>
                                <div className="pt-2"> 
                                    <span onClick={() => navigate('/')} className="passive_link_news">Home</span> >   
                                    <span onClick={() => navigate('/news')} className="passive_link_news ps-2">News</span> > {item.title}
                                </div>
                            </Container>
                        </div>
                    </div>

                    {/* CONTENT */}
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={9}>
                                <div class="banner_img_news_det">
                                    <img className="news_box_img" src={item.img_top} alt="header" />
                                </div>
                                <div className="banner_news_det mt-4">
                                    {item.isTop &&
                                        <div class="top_news">TOP NEWS</div>
                                    }
                                    {!item.isTop && isMostRead === 'true' &&
                                        <div className="mr_news">MOST READ</div>
                                    }
                                </div>
                                
                                <div className="flex-time_news">
                                    <div><AccessTimeIcon/> {convertDate(item.created_at)}</div>
                                    <div><PersonOutlineOutlinedIcon style={{background: '#5cc9dd', borderRadius: '50%'}}/> By {item.author}</div>
                                </div>

                                <div className="desc_news">{item.description}</div>

                                <div className="data_news" dangerouslySetInnerHTML={{ __html: item.data }} />
                            </Grid>
                            <Grid style={{marginTop: '2rem'}} item xs={12} sm={3}>
                                {otherItems.length !== 0 && 
                                <>
                                <div className="news_latest">
                                    Latest
                                </div>
                                <Grid container spacing={2}>
                                {otherItems.slice(0, 4).map(item =>
                                    <Grid item xs={6} sm={12} md={12}>
                                        <div onClick={() => {navigate('/news/'+item.id)}} className="news_box">
                                            <div className="shadowed_box"/>
                                            <img className="news_box_img" src={item.img_small} alt="bg" />
                                            <div className="text_news_box_z3">
                                                <div><AccessTimeIcon/> {convertDate(item.created_at)}</div>
                                                <div>{item.title}</div>
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                </Grid>
                                {
                                    otherItems.length > 4 &&
                                    <>
                                    <div style={{marginTop: '2rem'}} className="news_latest">
                                        Other
                                    </div>
                                    <Grid container spacing={2}>
                                    {otherItems.slice().reverse().slice(0, 4).map(item =>
                                        <Grid item xs={6} sm={12} md={12}>
                                            <div onClick={() => {navigate('/news/'+item.id)}} className="news_box">
                                                <div className="shadowed_box"/>
                                                <img className="news_box_img" src={test} alt="bg" />
                                                <div className="text_news_box_z3">
                                                    <div><AccessTimeIcon/> {convertDate(item.created_at)}</div>
                                                    <div>{item.title}</div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )}
                                    </Grid>
                                    </>
                                }
                                </>}
                            </Grid>
                        </Grid>
                    </Container>
                </>
            } 
        </div>
    )
}