import { useState, useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { Button, TextField } from '@mui/material';
import { setUser, setToken} from '../../redux/reducers/userQuestingReducer';
import axiosInstance from '../../redux/api';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

export default function GameAccSettings({setSuccess, setError}){
    const {account} = useSelector(state => state.userIskra);
    const {user} = useSelector(state => state.userQuesting);

    const [editPassword, setEditPassword] = useState(false);
    const [editName, setEditName] = useState(false);
    const [username, setUsername] = useState(user?.username ? user.username : '');
    
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setErrors] = useState('');
    const [showPass, setShowPass] = useState(false);

    useEffect(() => {
        setErrors('');
    }, [password, confirmPassword])
    

    const dispatch = useDispatch();

    const saveChanges = async () => {
        try{
            let str = '?username='+username;
            
            const res = await axiosInstance.put('/users/'+user?.id+str);
            if(res?.data?.data){
                setSuccess('Account updated successfully');
                dispatch(setUser(res?.data?.data));
                setEditName(false);

                let el1 = document.getElementById('input_username_settings');
                if(el1){
                    el1.blur();
                }
            }
        }catch(err){
            if(err.response?.data?.message){
                setError(err.response.data.message)
            }
        }
    }

    const changePassword  = async () => {
        if(password !== '' && confirmPassword !== ''){
            if(password === confirmPassword){
                setErrors('');
                try{
                    let str = '?password='+password+'&password_confirmation='+confirmPassword;
            
                    const res = await axiosInstance.put('/users/'+user?.id+str);

                    if(res?.data?.data){
                        setSuccess('Password updated');
                        closeForm();
                    }
                }catch(err){
                    if(err?.response?.data?.message){
                        setError(err?.response?.data?.message)
                    }
                }
            }else{
                setErrors('Passwords dont match');
            }
        }else{
            setErrors('Fill in the form');
        }
    }

    const syncAccount = async () => {
        try{
            let fd = new FormData();
            fd.append('user_id', account?.userId);
            fd.append('access_token', account?.accessToken);
            const res = await axiosInstance.post(process.env.REACT_APP_VERIFY_URL, fd)


            if(res?.data?.data?.token){
            dispatch(setToken(res.data?.data?.token))

            const result1 = await axiosInstance.get('auth/me');
            if(result1.data?.data){
                dispatch(setUser(result1.data?.data));
                setUsername(result1.data?.data?.username ? result1.data?.data?.username : '');
                setSuccess("Account synced");
            }
            }
        }catch{
            setError("Account didn't sync. Try again later");
        }
    }

    const makeInputEmail = () => {
        setEditPassword(false);
        setEditName(true);
        
        let el = document.getElementById('input_username_settings');
        if(el){
            el.focus();
        }
    }

    const makeInputPassword = () => {
        setEditName(false);
        setEditPassword(true);
    }

    const closeForm = () => {
        setEditName(false);
        setEditPassword(false);
        setUsername(user?.username);
        setPassword('');
        setConfirmPassword('');
    }

    return(
        <>
            {user.id === -1 &&
                <div className="settings_big_c">
                    <Button onClick={() => syncAccount()} variant="contained">Sync account</Button>
                </div>
            }
            {user.id !== -1 &&
                <div className="form_questing ps-0 pe-0">
                    {!editPassword && 
                    <>
                    <TextField 
                        id="input_username_settings"
                        InputProps={{ readOnly: !editName}} 
                        sx={{color: 'white'}} 
                        value={username}
                        onChange={(e) => setUsername(e.target.value)} 
                        size="small" fullWidth label="Username" 
                        className="input_quest" type="text" 
                    />

                    <div>
                        {!editName && 
                            <div style={{display: 'flex', justifyContent: 'flex-end', gap: '1rem'}}>
                                <Button onClick={() => makeInputEmail()} size="sm" variant="contained" className="mt-2 mb-2">
                                    Update username
                                </Button>

                                <Button onClick={() => makeInputPassword()} color="inherit" size="sm" variant="contained" className="mt-2 mb-2">
                                    Update password
                                </Button>
                            </div>
                        }

                        {editName &&
                            <div style={{display: 'flex', justifyContent: 'flex-end', gap: '1rem'}}>
                                <Button onClick={() => saveChanges()} size="sm" variant="contained" className="mt-2 mb-2">
                                    Save Changes
                                </Button>
                                <Button color="inherit" onClick={() => closeForm()} size="sm" variant="contained" className="mt-2 mb-2">
                                    Close
                                </Button>
                            </div>
                        }
                    </div>
                    </>
                    }

                    {editPassword && 
                    <>
                       <TextField 
                            sx={{color: 'white'}} 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)} 
                            size="small" fullWidth label="Password" 
                            className="input_quest" 
                            type={showPass ? "text" : "password"} 
                            InputProps={{
                                endAdornment: (
                                <InputAdornment onClick={() => setShowPass(!showPass)} style={{cursor: 'pointer'}} position="end">
                                    {!showPass ? <VisibilityOffIcon/> : <VisibilityIcon />}
                                </InputAdornment>
                                ),
                            }}
                        />
                        <TextField 
                            sx={{color: 'white'}} 
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                            size="small" fullWidth label="Confirm Password" 
                            className="input_quest"
                            type={showPass ? "text" : "password"} 
                        />
                        <div style={{display: 'flex', justifyContent: 'flex-end', gap: '1rem'}}>
                            <Button onClick={() => changePassword()} size="sm" variant="contained" className="mt-2 mb-2">
                                Change Password
                            </Button>
                            <Button color="inherit" onClick={() => closeForm()} size="sm" variant="contained" className="mt-2 mb-2">
                                Close
                            </Button>
                        </div>
                        <div className="error_form">
                            {error !== '' && <span>{error}</span>}
                        </div>
                    </>
                    }
                </div>
            }
        </>
    )
}