import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from '@amcharts/amcharts4/themes/dark';

import { useEffect } from "react";

const getRandomColdColor = (index) => {
  var blueColors = [
    "#9c8cfc",
    "#8c9cfc",
    "#8ccdfc",
    "#8cfce5",
    "#4e8ab5",
    "#6233ff",
    "#99e6e6",
    "#66cccc",
  ];

  var color = am4core.color(blueColors[index % blueColors.length]);
  var darkerColor = color.brighten(0.1); // Adjust the darkness level as needed

  var gradient = new am4core.LinearGradient();
  gradient.rotation = 180;
  gradient.addColor(color, 0);
  gradient.addColor(darkerColor, 1);

  return gradient;

  //return am4core.color(blueColors[index % blueColors.length]);
};

const DonutChart = ({data}) => {

  useEffect(() => {
      try{
        am4core.useTheme(am4themes_animated);
        am4core.useTheme(am4themes_dark);

        var chart = am4core.create("chartdiv", am4charts.PieChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        
        chart.background.fill = '#15192c8c'
        chart.background.opacity = 1

        chart.data = data;

        var series = chart.series.push(new am4charts.PieSeries());

        series.labels.template.disabled = true;


        series.dataFields.value = "value";
        series.dataFields.radiusValue = "radiusValue";
        series.dataFields.category = "country";
        series.slices.template.cornerRadius = 0;
        series.colors.step = 3;
        series.slices.template.strokeOpacity = 0;

        series.slices.template.fill = getRandomColdColor(0);
        series.slices.template.adapter.add("fill", function (fill, target) {
          return getRandomColdColor(target.dataItem.index);
        });

         // Create a div for the black cut circle
        var blackCutCircle = document.createElement("div");
        blackCutCircle.className = "black-cut-circle";
        document.getElementById("chartdiv").appendChild(blackCutCircle);

        // Create a div for the smaller black circle within the black cut circle
        var smallerBlackCircle = document.createElement("div");
        smallerBlackCircle.className = "smaller-black-circle";
        blackCutCircle.appendChild(smallerBlackCircle);

        return () => {
          chart.dispose();
        };
      }catch{
          //
      }
  }, [])

  return (
    <div id="chartdiv" className="donut-chart">
    </div>
  )
};

export default DonutChart;