import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';

import PageTitle from '../../helpers/PageTitle';
import axiosInstance from '../../redux/api';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WalletIcon from '@mui/icons-material/Wallet';
import TableQuests from '../../components/Admin/TableQuests';
import AddQuest from '../../components/Admin/AddQuest';
import TableUsers from '../../components/Admin/TableUsers';

export default function Dashboard({setSuccess, setError}){
    const [active, setActive] = useState('');

    const [users, setUsers] = useState([]);
    const [usersMeta, setUsersMeta] = useState({});
    const [loadingU, setLoadingU] = useState(true);
    
    const [quests,setQuests] = useState([]);
    const [questMeta, setQuestMeta] = useState({});
    const [loading, setLoading] = useState(true);
    const [addNew, setAddNew] = useState(false);

    const {path_settings} = useParams();
    const navigate = useNavigate();

    const getUsers = useCallback(
      async () => {
        try{
        const formData = new FormData();
        formData.append('reward_id', 'iKI4T')
        const result = await axiosInstance.get('admin/users', formData);
      
        if(result?.data?.data){
            setUsers(result.data.data);
            setUsersMeta(result?.data?.meta);
            setLoadingU(false);
          }
        }catch{}
      },
      [],
    )

    const getQuests = useCallback(
        async () => {
          try{
          const formData = new FormData();
          formData.append('reward_id', 'iKI4T')
          const result = await axiosInstance.get('admin/rewards', formData);
       
          if(result?.data?.data){
            setQuests(result.data.data);
            setQuestMeta(result?.data?.meta);
            setLoading(false);
          }
          }catch{}
        },
        [],
      )
    
    useEffect(() => {
        if(!path_settings){
            navigate('/admin');
        }else{
            if(path_settings === 'users' || path_settings === 'quests'){
                setActive(path_settings.toLowerCase());
                if(path_settings === 'users'){
                    getUsers();
                }
                if(path_settings === 'quests'){
                    getQuests();
                }
            }else{
                navigate('/admin');
            }
        }
    }, [path_settings, navigate, getUsers, getQuests])
    
    const goToLink = (link) => {
        navigate('/admin/'+link);
    }

    function capitalizeFirstLetter(string) {
        let strs = string.split(' ');
        let final = ''
        strs.forEach(s => {
            final+= ' ' + s.charAt(0).toUpperCase() + s.slice(1);
        })
        return final;
    }

    const appendQuests = (quest) => {
        if(quests){
            setQuests( // Replace the state
                [ // with a new array
                  ...quests, // that contains all the old items
                  quest // and one new item at the end
                ]
            );
        }
    }

    return(
        <div className="questing_page" style={{paddingTop: '5rem'}}>
            <PageTitle title="Admin Dashboard" />
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <List className="list_settings" component="nav" aria-label="list_settings">
                            <ListItemButton onClick={() => goToLink('users')}>
                                <ListItemIcon>
                                    <ManageAccountsIcon />
                                </ListItemIcon>
                                <ListItemText className={active === 'account' ? 'text_settings_active' : ''} primary="Users" />
                            </ListItemButton>
                            <ListItemButton onClick={() => goToLink('quests')}>
                                <ListItemIcon>
                                    <WalletIcon />
                                </ListItemIcon>
                                <ListItemText className={active === 'wallet' ? 'text_settings_active' : ''}  primary="Quests" />
                            </ListItemButton>
                            
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {path_settings &&
                            <>
                            <div className="sign_in_par_quest ps-4 pe-4">
                                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                                    <h4 className="pt-3 pb-2">{capitalizeFirstLetter(String(path_settings).replaceAll('-', ' ')) + ''}</h4>
                                    {path_settings === 'quests' && <Button size="small" className="btn_quest mt-0" onClick={() => setAddNew(true)} variant="contained">Add New</Button>}
                                </div>
                                <Divider />
                                {path_settings === 'users' && 
                                    <TableUsers meta={usersMeta} loading={loadingU} users={users} setUsers={setUsers} setSuccess={setSuccess} setError={setError} setLoading={setLoadingU} setMeta={setUsersMeta}/>
                                }
                                {path_settings === 'quests' && 
                                    <TableQuests loading={loading} meta={questMeta} quests={quests} setQuests={setQuests} setSuccess={setSuccess} setError={setError} setLoading={setLoading} setQuestMeta={setQuestMeta}/>
                                }

                             </div>
                            </>
                        }
                        
                    </Grid>
                </Grid>
            </Container>

            <AddQuest setShowAcc={setAddNew} appendQuests={appendQuests} showAcc={addNew} setSuccess={setSuccess} />
        </div>
    )
}