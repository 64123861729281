import '../assets/css/Forge.css';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import '../../src/assets/css/responsive/carouser.scss';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PageTitle from '../helpers/PageTitle';

function Forge() {
    const divsArray = Array.from({ length: 6 }, (_, index) => index);
    const [active, setActive] = useState(2);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedAbility, setSelectedAbility] = useState(null);
    const [selectedSlots, setSelectedSlots] = useState(Array.from({ length: 6 }, () => ({ selectedAbility: null })));
    const [show, setShow] = useState(false);

    const [classForge, setClassForge] = useState([
        { id: 0, name: 'LEMIAN', type: 'Melee Assasin', img: '/static/heroes/elf.png' },
        { id: 1, name: 'WISELIAN', type: 'Melee Warrior', img: '/static/heroes/kotl.png' },
        { id: 2, name: 'L.A.R', type: 'Melee Wizard', img: '/static/heroes/robot.png' },
        { id: 3, name: 'KING HODRICK', type: 'Range Assasin', img: '/static/heroes/skeleton.png' },
        { id: 4, name: 'GAIALITH', type: 'Range Warrior', img: '/static/heroes/titan.png' },
        { id: 5, name: 'DARFIN', type: 'Range Wizard', img: '/static/heroes/dark.png' },
    ]);

    const [abilityForge, setAbilityForge] = useState([
        { id: 0, name: 'Tower 1', type: 'Tower1', img: '/static/forge/tower1.png' },
        { id: 1, name: 'Tower 2', type: 'Tower2', img: '/static/forge/tower2.png' },
        { id: 2, name: 'Tower 3', type: 'Tower3', img: '/static/forge/tower3.png' },
        { id: 3, name: 'Tower 4', type: 'Tower4', img: '/static/forge/tower4.png' },
        { id: 4, name: 'Tower 5', type: 'Tower5', img: '/static/forge/tower5.png' },
        { id: 5, name: 'Tower 6', type: 'Tower6', img: '/static/forge/tower6.png' },
        { id: 6, name: 'Tower 7', type: 'Tower7', img: '/static/forge/tower7.png' },
        { id: 7, name: 'Tower 8', type: 'Tower8', img: '/static/forge/tower8.png' },
        { id: 8, name: 'Tower 9', type: 'Tower9', img: '/static/forge/tower9.png' },
        { id: 9, name: 'Tower 10', type: 'Tower10', img: '/static/forge/tower10.png' },
        { id: 10, name: 'Tower 11', type: 'Tower11', img: '/static/forge/tower11.png' },
    ]);

    const handleClose = () => {
        setShow(false);
    }
    const handleClose2 = () => {
        const updatedSelectedSlots = [...selectedSlots];
        updatedSelectedSlots[selectedSlot - 1] = { selectedAbility: null };
        setSelectedSlots(updatedSelectedSlots);
        setShow(false);
        setSelectedAbility(null);
    }
    const handleShow = (index) => {
        setSelectedSlot(index + 1);
        setSelectedAbility(null)
        setShow(true);
    };

    function moveLeft() {
        setActive(active => (active - 1 + classForge.length) % classForge.length);
    }

    function moveRight() {
        setActive(active => (active + 1) % classForge.length);
    }

    function handleAbilityClick(abilityId) {
        const updatedSelectedSlots = [...selectedSlots];
        updatedSelectedSlots[selectedSlot - 1] = { selectedAbility: abilityId };
        setSelectedSlots(updatedSelectedSlots);
        setSelectedAbility(abilityId)
    }

    function handleSelect() {
        const updatedSelectedSlots = [...selectedSlots];
        updatedSelectedSlots[selectedSlot - 1] = { selectedAbility: selectedAbility };
        setSelectedSlots(updatedSelectedSlots);
        setShow(false);
        setSelectedAbility(null);
    }

    function generateItems() {
        const items = [];
        for (let i = active - 2; i <= active + 2; i++) {
            let index = i < 0 ? classForge.length + i : i % classForge.length;
            let level = active - i;

            if (level > 1) level = 2;
            if (level < -1) level = -2;

            items.push(
                <div className={`item level${level}`} key={index}>
                    <img src={classForge[index].img} alt={classForge[index].name} />
                    <div className="basedOn">
                        <span>{classForge[index].name}</span>
                    </div>
                </div>
            );
        }
        return items;
    }

    return (
        <section className="ForgeS">
            <PageTitle title="Forge" />
            <div className='topF'>
                <div className='topFL'>
                    <span>Forge</span>
                    <span>
Forging a guardian costs x $Spark Token and ISK. Players can customize body parts and boost stats by allocating more tokens, starting with 10% for each part. The process takes 4 days to complete.</span>
                </div>
                <div className='topFR'>
                    <span>Forge Combination</span>
                </div>
            </div>
            <div className='forgeB'>
                <div className='slots'>
                    {selectedSlots.map((slot, index) => (
                        <div className="slotWIthN" onClick={() => handleShow(index)} key={index}>
                            <div className='singleSlot'>
                                {slot.selectedAbility !== null ? (
                                    <div className='selectedDivIm'>
                                        <img src={abilityForge[slot.selectedAbility].img} alt={abilityForge[slot.selectedAbility].name} />
                                    </div>
                                ) : (
                                    <>
                                        <AddIcon />
                                        <div className='insideForgeImg'></div>
                                    </>
                                )}
                            </div>
                            <span>Slot {index + 1}</span>
                        </div>
                    ))}
                </div>
                <div className='heroes'>
                    <div id="carousel" className="noselect">
                        <div className='arrow arrow-left'>
                            <KeyboardArrowLeftIcon onClick={() => moveLeft()} />
                        </div>
                        {generateItems()}
                        <div className='arrow arrow-right'>
                            <ChevronRightIcon onClick={() => moveRight()} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose2} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Slot: {selectedSlot}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='formsAb'>
                        {abilityForge.map((ability) => (
                            <div key={ability.id} className={`singleAbility ${selectedAbility === ability.id ? 'selected' : ''}`} onClick={() => handleAbilityClick(ability.id)}>
                                <div className='abilityImgg'>
                                    <img src={ability.img} alt={ability.name} />
                                </div>
                                <span>{ability.name}</span>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" onClick={handleSelect}>
                        Select
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default Forge;
