import { Avatar } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import logoMtech from '../assets/img/m-tech-logo-white.png';
import logoIskra from '../assets/img/iskra-logo.webp';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";

function Footer(){
    const goTo = (link) => {
        window.open(link, "_blank");
    }

    return(
        <footer className="footer-section">
            <div className="top-break__home">
                <div className="top-breaker-t__home" style={{background: 'radial-gradient(100% 100% at 100% 100%,#2a479738 .21%,rgb(105 41 255 / 0%) 100%)'}}></div>
                <div className="top-breaker-m__home" style={{background: 'linear-gradient(90deg,#23409336 -.04%,#fff3b5b0 52.07%,#234093bf)'}}></div>
                <div className="top-breaker-b__home" style={{background: 'radial-gradient(50% 100% at 50% 100%,#2a47976b 0,rgba(30,84,183,0) 100%)'}}></div>
            </div>
            <Row className="flex-container__footer pb-4">
                <Col style={{minWidth: '10rem'}}>
                    <div className="title__footer"> FOLLOW US </div>
                    <div className="icons__footer pt-3">
                        <div className="icon-menu icon-menu-hover" >
                            <FontAwesomeIcon style={{padding: '.6rem .5rem'}}  onClick={() => goTo('https://discord.com/invite/KS7cWmCtgG')}  size="sm" icon={faDiscord} />
                        </div>
                        <div className="icon-menu icon-menu-hover" >
                            <FontAwesomeIcon style={{padding: '.6rem'}} size="sm" onClick={() => goTo('https://x.com/playgots')}  icon={faTwitter} />
                        </div>
                    </div>
                </Col>
                <Col style={{minWidth: '10rem'}}>
                    <div className="title__footer"> POWERED BY </div>
                    <Container sx={{ paddingLeft: '0 !important',paddingRight: '0 !important'}} className="icons-container-menu pt-3" style={{paddingLeft: '0'}}>
                        <img className="logoMtech icon-menu-hover" src={logoMtech} alt='logoMtech' />
                        <Avatar className="logoIskra icon-menu-hover" onClick={() => goTo('https://iskra.world/')} variant="square" sx={{ width: { md: '3rem' } }} src={logoIskra} />
                    </Container>
                </Col>
            </Row>
            <div className="copyright pb-3">
                © Copyright 2024 By MTech
            </div>
        </footer>
    )
}

export default Footer;