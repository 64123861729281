import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userF: null
};

const adminFirebaseSlice = createSlice({
    name: "adminFirebase",
    initialState: initialState,
    reducers: {
        setFirebaseCred: (state, action) => {
            state.userF = action.payload;
        },
        logOutFirebaseCred:(state) => {
            state.userF = null;
        },
    }
});

export const {setFirebaseCred, logOutFirebaseCred} = adminFirebaseSlice.actions;

export default adminFirebaseSlice.reducer;