import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc,setDoc } from 'firebase/firestore';

import db from '../../redux/firebase';
import { Container, Button, Grid } from '@mui/material';
import Form from 'react-bootstrap/Form';
import { serverTimestamp } from 'firebase/firestore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import InputGroup from 'react-bootstrap/InputGroup';
import FireBaseImages from './FireBaseImages';

export default function NewsAdminEdit(){
    const {id} = useParams();
    const navigate = useNavigate();

    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);

    const [author, setAuthor] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDesc] = useState('');
    const [isTop, setIsTop] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    
    const [imgSmall, setImgSmall] = useState('');
    const [imgTop, setImgTop] = useState('');
    const [imgLong, setImgLong] = useState('');

    const [editorHtml, setEditorHtml] = useState('');
    const [validated, setValidated] = useState(false);

    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    function htmlToText(html) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || '';
    }

    useEffect(() => {
        const fetchItem = async () => {
        try {
            if(id !== 'new'){
                const docRef = doc(db.news, 'news', id); // Replace 'yourCollectionName' with your actual collection name
                const docSnap = await getDoc(docRef);
        
                if (docSnap.exists()) {
                    setItem({ 
                        id: docSnap.id, 
                        ...docSnap.data() });
                        
                    setTitle(docSnap.data()?.title);
                    setAuthor(docSnap.data()?.author);
                    setDesc(docSnap.data()?.description);
                    setIsTop(docSnap.data()?.isTop);
                    setImgLong(docSnap.data()?.img_header);
                    setImgSmall(docSnap.data()?.img_small);
                    setImgTop(docSnap.data()?.img_top);
                    setEditorHtml(docSnap.data()?.data);
                    setIsVisible(docSnap.data()?.isVisible);
                } else {
                    navigate('/admin/news')
                }
            }
        } catch (error) {
            setError('Error fetching document:', error);
        } finally {
            setLoading(false);
        }
        };
      if(id){
        fetchItem();
      }
    }, [id, navigate])

    const handleEditorChange = (html) => {
        setEditorHtml(html);
    };

    const submit = async (e) => {
        e.preventDefault();
        setSuccess('');
        setError('');
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.stopPropagation();
        }

        setValidated(true);

        if(id === 'new'){
            try {
                const newItem = {
                    author: author,
                    created_at: serverTimestamp(),
                    data: editorHtml,
                    description: description,
                    img_small: imgSmall,
                    img_top: imgTop,
                    img_header: imgLong,
                    isTop: isTop,
                    title: title,
                    isVisible: isVisible,
                    views: 0,
                    viewers: []
                };

                const newsCollectionRef = doc(db.news, 'news', title.toLowerCase().replaceAll(' ', '-'));
                await setDoc(newsCollectionRef, newItem);
            
                setSuccess('Document successfully written');
            } catch (error) {
                setError('Error adding document:', error);
            }
        }else{
            try {
                const itemDocRef = doc(db.news, 'news', item?.id);

                let newItem = {
                  author: author,
                  data: editorHtml,
                  description: description,
                  img_small: imgSmall,
                  img_top: imgTop,
                  img_header: imgLong,
                  isTop: isTop,
                  title: title,
                  isVisible: isVisible,
                };
               
                await updateDoc(itemDocRef, newItem);

                setSuccess('Document successfully written with ID:', item?.id);
                
              } catch (error) {
                setError('Error adding document:', error);
              }

        }
    }
    
    const modules = {
        toolbar: {
          container: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            [{ 'color': [] }, { 'background': [] }], // Include color and background color options
            ['link', 'image', 'video'],
            ['clean']
          ]
        }
      };

      const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'color', 'background' // Include color and background formats
      ];

    return(
        <div className="questing_page" style={{paddingTop: '5rem'}}>
            {!loading &&
                <Container>
                    <Form noValidate validated={validated} onSubmit={(e) => submit(e)} data-bs-theme="dark">
                        <Button variant="outlined" className="mb-4 me-2" onClick={() => navigate('/admin/news')}>Back</Button>
                        <Button variant="contained" className="mb-4" type="submit">{id === 'new' ? 'Create' : 'Edit'}</Button>
                        {error && <p className="text-error">{error}</p>}
                        {success && <p className="text-success">{success}</p>}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control required={id === 'new'} disabled={id !== 'new'} value={title} onChange={e => setTitle(e.target.value)} type="text"/>
                                </Form.Group>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Author</Form.Label>
                                    <Form.Control required value={author} onChange={e => setAuthor(e.target.value)}  type="text" />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Subtitle / Short Description</Form.Label>
                                    <Form.Control required value={description} onChange={e => setDesc(e.target.value)}  type="text" />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container style={{height: '100%'}}  spacing={2}>
                                    <Grid style={{height: '100%'}}  item xs={6} sm={6}>
                                        <Form.Group className="mb-3" style={{height: '100%'}} controlId="exampleForm.ControlInput1">
                                            <div className="pb-3" style={{display: 'flex', alignItems: 'flex-end', height: '100%'}}>
                                            <Form.Check // prettier-ignore
                                                type='checkbox'
                                                id={`isTop-checkbow`}
                                                label={`Is Top`}
                                                checked={isTop} 
                                                onChange={(e) => setIsTop(e.target.checked)}
                                            />
                                            </div>
                                        </Form.Group>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                    <Form.Group className="mb-3" style={{height: '100%'}} controlId="exampleForm.ControlInput1">
                                            <div className="pb-3" style={{display: 'flex', alignItems: 'flex-end', height: '100%'}}>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    id={`isVisible-checkbow`}
                                                    label={`Is Visible`}
                                                    checked={isVisible} 
                                                    onChange={(e) => setIsVisible(e.target.checked)}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Image for listings (small -> 1200 x 716)</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control required={id === 'new'} value={imgSmall} onChange={e => {setImgSmall(e.target.value)}}  type="text" />
                                       
                                    </InputGroup>
                                </Form.Group>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Image for top (medium -> 1200 x 526)</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control required={id === 'new'} value={imgTop} onChange={e => {setImgTop(e.target.value)}}  type="text" />
                                    </InputGroup>
                                </Form.Group>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Image for header (long -> 1200 x 138)</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control required={id === 'new'} value={imgLong} onChange={e => {setImgLong(e.target.value)}}  type="text" />
                                    </InputGroup>
                                </Form.Group>
                            </Grid>
                        </Grid>

                      
                       
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Content</Form.Label>
                            <ReactQuill
                                theme="snow"
                                value={editorHtml}
                                onChange={handleEditorChange}
                                modules={modules}
                                formats={formats}
                                placeholder="Write your HTML content here..."
                            />
                            {/* 
                            <div className="pt-2">
                                <Form.Label> Html Content: </Form.Label>
                            
                                Directly outputting in JSX will escape HTML 
                                <div>{editorHtml}</div>
                            </div>*/}
                        </Form.Group>
                    </Form>
                </Container>
            }
            <div className="pt-5 pb-5">
                <FireBaseImages />
            </div>
        </div>
    )
}