import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TextField } from '@mui/material';
import axiosInstance from '../../redux/api';
import { setUser } from '../../redux/reducers/userQuestingReducer';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function AddQuest({showAcc, setShowAcc, setSuccess, appendQuests}){
    const [reward_id, setRewardId] = useState('');
    const [reward_name, setRewardName] = useState('');
    const [credits_amount, setCreditsAmount] = useState('');
    const [error, setErrors] = useState('');

    const matches = useMediaQuery('(max-width:600px)');

    const fullscreen = matches;


    const handleClose = () => setShowAcc(false);
    
    useEffect(() => {
        setErrors('');
    }, [reward_id, reward_name, credits_amount])
    
    const setProfile  = async () => {
        if(reward_id !== '' && reward_name !== '' && credits_amount !== ''){
                setErrors('');
                try{
                    const form = new FormData();
                    form.append('reward_id', reward_id);
                    form.append('reward_name', reward_name);
                    form.append('credits_amount', credits_amount)
            
                    const res = await axiosInstance.post('admin/rewards', form);

                    if(res?.data?.data){
                        appendQuests(res?.data?.data)
                        setSuccess('Reward added successfully');
                        setShowAcc(false);
                    }
                }catch(err){
                    if(err?.response?.data?.message){
                        setErrors(err?.response?.data?.message)
                    }
                }
        }else{
            setErrors('Fill in the form');
        }
    }
  
    return (
        <Modal
          fullscreen={fullscreen}
          show={showAcc}
          onHide={handleClose}
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title style={{textAlign: 'center', width: '100%'}}>Create New Quest</Modal.Title>
          </Modal.Header>
          <Modal.Body className="body_setacc">
            <div className="form_questing ps-0 pe-0">
                <TextField 
                    id="input_username_settings" 
                    sx={{color: 'white'}} 
                    value={reward_id}
                    onChange={(e) => setRewardId(e.target.value)} 
                    size="small" fullWidth label="Reward ID" 
                    className="input_quest" type="text" 
                />
                 <TextField 
                    sx={{color: 'white'}} 
                    value={reward_name}
                    onChange={(e) => setRewardName(e.target.value)} 
                    size="small" fullWidth label="Reward Name" 
                    className="input_quest" 
                    type='text'
                />
                <TextField 
                    sx={{color: 'white'}} 
                    value={credits_amount}
                    onChange={(e) => setCreditsAmount(e.target.value)} 
                    size="small" fullWidth label="Credits Amount" 
                    className="input_quest" 
                    type='text'
                />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{width: '100%'}} onClick={() => setProfile()}  variant="primary">Create</Button>
            <div className="error_form">
                {error !== '' && <span>{error}</span>}
            </div>
          </Modal.Footer>
        </Modal>
    );
}