import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useSelector, useDispatch} from 'react-redux';
import { setUser, setToken} from '../../redux/reducers/userQuestingReducer';
import axiosInstance from '../../redux/api';

export default function SignInQuesting({setSuccess, setError}){
    const dispatch = useDispatch();
    const {account} = useSelector(state => state.userIskra);

    const enroll = async (e) => {
        e.preventDefault();

        try{
            let fd = new FormData();
            fd.append('user_id', account?.userId);
            fd.append('access_token', account?.accessToken);
            const res = await axiosInstance.post(process.env.REACT_APP_VERIFY_URL, fd)

            if(res?.data?.data?.token){
            dispatch(setToken(res.data?.data?.token))

            const result1 = await axiosInstance.get('auth/me');
            if(result1.data?.data){
                dispatch(setUser(result1.data?.data))
                setSuccess('Successfully enrolled');
            }
            }
        }catch(err){
            if(err?.response?.data?.message){
                setError(err?.response?.data?.message)
            }
        }
    }
    return(
        <div className="sign_ques_cont">
           
            <Container maxWidth="md">
                <div className="sign_in_par_quest">
                        <h5 className="h6_questing pt-3">Enroll to Questing</h5>
                        <form className="form_questing">
                            <Button className="btn_quest" type="submit" onClick={(e) => {enroll(e)}} variant="contained">Enroll</Button>
                        </form>
                    {/*!signIn &&
                    <>
                        <h5 className="h6_questing pt-3">Sign Up for Questing</h5>
                        <form className="form_questing">
                            <TextField sx={{color: 'white'}} error={afterSubmit && !validateEmail(email)} helperText={afterSubmit && !validateEmail(email) ? "Incorrect email format" : ''}  value={email} onChange={e => setEmail(e.target.value)}  size="small" fullWidth label="Email" className="input_quest" type="email" />
                            <TextField sx={{color: 'white'}} error={afterSubmit && !validatePassword(password)} helperText={afterSubmit && !validatePassword(password) ? "Password must be at least 8 characters long" : ''}  value={password} onChange={e => setPassword(e.target.value)}  size="small" fullWidth label="Password" className="input_quest" type="password" />
                            <TextField InputProps={{ readOnly: true}} sx={{color: 'white'}} error={afterSubmit && !validateAddress(wallet)} helperText={afterSubmit && !validateAddress(wallet) ? "Invalid wallet address" : ''}  value={wallet} size="small" fullWidth label="Wallet Address" className="input_quest" type="text" />
                            {!account && <ThirdwebBtn />}
                            <Button className="btn_quest" type="submit" onClick={(e) => {singUp(e)}} variant="contained">Sign up</Button>
                            <p className="p_questing">Already have an account? 
                            <span className="ps-1" onClick={() => {setSignIn(true)}}>Sign In</span></p>
                            {errors !== '' && <p className="text-red text-center">{errors}</p>}
                        </form>
                    </>
                    */}
                </div>
            </Container>
        </div>
    )
}